import React, { useState, Fragment } from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, CardFooter, Table, Input, InputGroup,
  InputGroupAddon,
  InputGroupText, Collapse,Label } from 'reactstrap'
import axios from 'axios';
import { useEffect } from 'react';
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Search, MapPin } from 'react-feather';
import ScrollArea from "react-scrollbar";
import DSH from "../../assets/images/blog/DASHBOARD4.png";


const steps = [
  {
    selector: '.p1',
    content: 'Esta es la lista de Proveedores dentro de B2Buysel',
  },
  {
    selector: '.p2',
    content: 've a tu sección de compradores y crea un RFQ dependiendo la categoria que te interese',
  },
  {
    selector: '.p3',
    content: 'Aqui busca tu categoria para ver los proveedores disponibles.',
  },

]
const lslocal = require("../widgets/datosprov/datosprov.json");

const URL = "https://b2bhubapi.com:8000/pruebas/listprov"
const getData = async () => {
  const response = axios.get(URL);
  return response;

}
const RFQSprov = () => {
  //tour
  const [opentour, setopentour] = useState(false)
  const closeTour = () => {
    setopentour(false);
  };

  const tuto = () => {
    setTimeout(() => {
      setopentour(true);
    }, 350);
  }

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  //lod emas
  const [date, setDate] = useState({ date: new Date() });
  const handleChange = date => {
    setDate(date)
  };

  const [seglist, setseglist] = useState([])


    getData().then((response) => {
      setseglist(response.data);
    })



  const [isFilter, setIsFilter] = useState(true);
  const [keyWord, setKeyWord] = useState("");
  const [serachedItems, setSearchedItems] = useState([]);

  const searchKeyWord = () => {
    if (keyWord != "") {
      let itemsMatched = seglist.filter((item) =>
        item.Categoria.toLowerCase().includes(keyWord)
      );
      setSearchedItems(itemsMatched);
    } else {
      setSearchedItems([]);
    }
  };


  return (
    <Fragment>
      <Card>
                <CardHeader>
                <img className="pull-left" src={DSH} style={{height: "35px"}} alt=""/>
                <Button className="btn-pill pull-right" color="success-gradien" size="lg" onClick={tuto}>{"Tutorial"}</Button>
                </CardHeader>
              </Card> 
      <Tour
        steps={steps}
        rounded={5}
        isOpen={opentour}
        disableInteraction={true}
        disableKeyboardNavigation={false}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <Container fluid={true}>
        <Row>

          <Col xl="3 xl-40">
            <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button color="link pl-0" data-toggle="collapse" onClick={() => setIsFilter(!isFilter)}
                          data-target="#collapseicon" aria-expanded={isFilter} aria-controls="collapseicon">{"Filtro por categoría"}</Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                      <CardBody className="filter-cards-view animate-chk">
                        <div className="job-filter">
                          <div className="faq-form">
                            <InputGroup className="p3">
                              <Input
                                type="text"
                                class="form-control m-t-5 m-b-5"
                                placeholder="Buscar"
                                value={keyWord}
                                disabled={serachedItems.length == 0 ? false : true}
                                onChange={(event) =>
                                  setKeyWord(event.target.value.toLowerCase())
                                } />
                              <Search className="search-icon" />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  {serachedItems.length == 0 ? (
                                    <Button color="warning" onClick={searchKeyWord}>
                                      {"Buscar"}
                                    </Button>
                                  ) : (
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        setKeyWord("");
                                        setSearchedItems([]);
                                      }}
                                    >
                                      {"Limpiar"}
                                    </Button>
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>

                          </div>
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                </Col>

              </Row>
            </div>
          </Col>


          <Col xl="9 xl-60">
            <Card>
              <CardBody>
                <div className="user-status table-responsive"> 
                  
              {serachedItems.length == 0 ? (
                <ScrollArea horizontal={true} vertical={true}>
                 <Table className="p1" >
                 <thead>
                   <tr>
                     <th scope="col">{"Empresa"}</th>
                     <th scope="col">{"Categoría de productos"}</th>
                   </tr>
                 </thead>
                      <tbody>                       
                      {lslocal.map((data) => {   
                     const emp = data.Empresa.slice(0, data.Empresa.length - 10 ) + "...."

                        return(
                        <tr >
                          <td>{emp}</td>
                          <td className={`p2`}>{data.Categoria}</td>
                          {/* <td>{data.Correo}</td> */}
                        </tr>
                        )
                      }
                      )}
                    </tbody>
                    </Table>   
                    </ScrollArea>
        
              ) : (
                <ul className="p-10" >
                   <ScrollArea horizontal={true} vertical={true}>
                            <Table className="p1" >  
                      <thead>
                        <tr>
                          <th scope="col">{"Empresa"}</th>
                          <th scope="col">{"Categoria de productos"}</th>
                        </tr>
                      </thead> 
                   {serachedItems.map((subClas, e) => {
                                  
                                  const empres = subClas.Empresa.slice(0, subClas.Empresa.length - 10 ) + "...."
                  
                             return (        
                           <tbody>
                             <tr >
                                 
                               <td>{empres}</td>
                               <td className={`p2`}>{subClas.Categoria}</td>
                               {/* <td>{data.Correo}</td> */}
                               
                             </tr>
                         </tbody>
                                  );      
                   
                  })} 
                  </Table>  
                   </ScrollArea>
                </ul>
              )}            
                 
                </div>
              </CardBody>
            </Card>


          </Col>
        </Row>
      </Container>

    </Fragment>
  )
}

export default RFQSprov
