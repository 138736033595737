import React, { useState, Fragment, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, CardFooter, Table, Input, InputGroup,InputGroupAddon,InputGroupText, Collapse,Label,Modal, ModalHeader, ModalBody,ModalFooter, FormGroup,Media } from 'reactstrap'
import rep from '../../assets/images/blog/REPORTE.png'
import TextField from '@mui/material/TextField';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {TrendingUp} from 'react-feather'
import ScrollArea from "react-scrollbar";
import DatePicker from "react-datepicker";
import axios from 'axios';
const Reporte = () =>{

    //Variables
    const [usuarioslist, setusuarioslist] = useState([])
    const [rfqslist, setrfqslist] = useState([])
    const [aceptlist, setaceptlist] = useState([])
    const [modalur, setmodalur] = useState(false);
    const [modalrf, setmodalrf] = useState(false);
    const [modalurp, setmodalurp] = useState(false);
    const [modalacept, setmodalacept] = useState(false);
    const [modalcat, setmodalcat] = useState(false);

    const openmodalur = () =>{
        setmodalur(true)
    }
    const openmodalrf = () =>{
        setmodalrf(true)
    }

    const openmodalurp = () =>{
        setmodalurp(true)
    }
    const openmodalacept = () =>{
        setmodalacept(true)
    }
    const openmodalcat = () =>{
        setmodalcat(true)
    }
    
    
    const toggleur = () =>{
        setmodalur(false)
    }
    const togglerf = () =>{
        setmodalrf(false)
    }


    const toggleurp = () =>{
        setmodalurp(false)
    }
    const toggleacept = () =>{
        setmodalacept(false)
    }
    const togglecat = () =>{
        setmodalcat(false)
    }


    //llamada a la API
    
    useEffect(() => {
        axios.get('https://b2bhubapi.com:5050/pruebas/acept').then((response) => {
              setaceptlist(response.data);
            });
          }, []);

    useEffect(() => {
        axios.get('https://b2bhubapi.com:7575/pruebas/segundalista').then((response) => {
              setrfqslist(response.data);
            });
          }, []);
    
    useEffect(() => {
        axios.get('https://b2bhubapi.com:8000/pruebas/usuarioslist').then((response) => {
              setusuarioslist(response.data);
            });
          }, []);
          
      //Fecha de corte
      const [startDate,setstartDate] = useState(new Date())
      const [endDate,setendDate] = useState(new Date())
      const handleChange = date => {
        setstartDate(date);
      };
      const setEndDate = date => {
        setendDate(date);
       };

      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido)
      var el = hoy.getDate().toString();
      if (el.length == 1){ 
      el = "0"+ el;
      }
      var getMonth = (hoy.getMonth()+1).toString();
      if (getMonth.length == 1){
      getMonth = "0"+getMonth;
      }
      var getYear = hoy.getFullYear().toString();
      var fecha = el + "/" + getMonth + "/" + getYear;


      var s1 = startDate.getDate().toString();
      if (s1.length == 1){ 
        s1 = "0"+ s1;
        }
      var s2 = (startDate.getMonth()+1).toString();
      if (s2.length == 1){
      s2 = "0"+s2;
      }
      var s3 = startDate.getFullYear().toString();
      var starting = new Date(s3 + "-" + s2 + "-" + s1)

      var e1 = endDate.getDate().toString();
      if (e1.length == 1){ 
        e1 = "0"+ e1;
        }
      var e2 = (endDate.getMonth()+1).toString();
      if (e2.length == 1){
      e2 = "0"+e2;
      }
      var e3 = endDate.getFullYear().toString();
      var ending =  new Date(e3 + "-" + e2 + "-" + e1)
     
      

    //Extraccion de datos
    
    let compradores = usuarioslist.filter(obj => new Date(obj.Fecha) >= starting &&  new Date(obj.Fecha)<= ending && obj.role == "Comprador")
    let proveedores = usuarioslist.filter(obj => new Date(obj.Fecha) >= starting && new Date(obj.Fecha) <= ending && obj.role == "Proveedor")
    let cates = usuarioslist.filter(obj => new Date(obj.Fecha) >= starting && new Date(obj.Fecha) <= ending && obj.role == "Proveedor") 
    let rfqs = rfqslist.filter(obj => new Date(obj.Fecha) >= starting && new Date(obj.Fecha) <= ending) 
    let acept = aceptlist.filter(obj => new Date(obj.Fecha) >= starting && new Date(obj.Fecha) <= ending)

    //funciones de boton

    //Interfaces
    const contadorcomp1 = [
        { id: 1, cardBg: 'bg-secondary', title: "Registrados", scorr: compradores.length , color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress: "1" + compradores.length +"%" },
      ]
      const contadorcomp2 = [
        { id: 2, cardBg: 'bg-secondary', title: "Número de RFQ's generados", scorr: rfqs.length , color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress:  "1" + rfqs.length +"%"},
      ]


      const contadorprov1 = [
        { id: 1, cardBg: 'bg-primary', title: "Registrados", scorr: proveedores.length , color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress:  "1" + proveedores.length +"%" },
      ]
      const contadorprov2 = [
        { id: 2, cardBg: 'bg-primary', title: "Número de propuestas", scorr: acept.length , color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress: "1" + acept.length +"%" },
      ]
      const contadorprov3 = [
        { id: 3, cardBg: 'bg-primary', title: "Categorias registradas", scorr: "", color: 'primary', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress: '' },
      ]

    return(
         <Fragment>
            <Card>
                <CardHeader>
                <img className="pull-left" src={rep} style={{height: "35px"}} alt=""/>
                <Row className='pull-center' style={{width: "700px",top: "2px",position: "relative", left: "391.296px"}}>
                <FormGroup className="form-row">
                <label className="col-sm-3 col-form-label text-right">{"Desde:"}</label>
                <div className="col-xl-5 col-sm-9">
                <DatePicker  className="form-control digits" dateFormat="yyyy-MM-dd" selected={startDate} onChange={handleChange}/>
                </div>
                </FormGroup>
                <FormGroup className="form-row">
                <label className="col-sm-3 col-form-label text-right">{"Hasta:"}</label>
                <div className="col-xl-5 col-sm-9">
                <DatePicker  className="form-control digits" dateFormat="yyyy-MM-dd" selected={endDate} onChange={setEndDate}/>
                </div>
                </FormGroup>
                 </Row>
                </CardHeader>
              </Card>
              <Row>
              <Col xl="3" className="xl-50 col-6 hospital-patients box-col-6">
              <Card className="o-hidden">
              <CardBody>
                <div className="hospital-widgets media">
               <div className="media-body">
               <div className="hospital-content">
               <h3 className="d-inline-block f-w-600">{"Compradores"}</h3>
               </div>
               <div className="flowers">
               <div className="flower1"></div>
               <div className="flower2"></div>
               <div className="flower3"></div>
               </div>
               </div>
               </div>
               </CardBody>
               <CardFooter>
               {contadorcomp1.map(data => (
                <Col key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <h3 className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</h3>
                            <h3 className="mt-0 mb-0 f-w-600 pull-right"><span className="counter">{data.scorr}</span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">  
                            </div>
                        </div>
                        <Button className="btn-pill pull-right"  color="success-gradien" onClick={openmodalur}>{"Ver detalles"}</Button> 
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {contadorcomp2.map(data => (
                <Col key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <h3 className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</h3>
                            <h3 className="mt-0 mb-0 f-w-600 pull-right"><span className="counter">{data.scorr}</span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">  
                            </div>
                        </div>
                        <Button className="btn-pill pull-right"  color="success-gradien" onClick={openmodalrf}>{"Ver detalles"}</Button> 
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
               </CardFooter>
               </Card>
               </Col>


               <Modal isOpen={modalur} toggle={toggleur} className="modal-body" size="lg" centered={true}>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"Nombre"}</th>
                          <th scope="col">{"Empresa"}</th>
                          <th scope="col">{"Correo"}</th>
                          <th scope="col">{"Fecha de creación"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {compradores.map(data => (
                        <tr >
                        <td>{data.Nombre}</td>
                        {data.Empresa == "" ? (
                            <td>{"Sin empresa (Persona Moral)"}</td>
                                  ) : (
                            <td>{data.Empresa}</td>
                                  )}
                        <td>{data.Correo}</td>
                        <td>{data.Fecha}</td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                         </ScrollArea>
                        </ModalBody>
                </Modal>
                <Modal isOpen={modalrf} toggle={togglerf} className="modal-body" size="lg" centered={true}>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                              <th scope="col">{"RFQ"}</th>
                              <th scope="col">{"Categoria"}</th>
                              <th scope="col">{"Descripción"}</th>
                              <th scope="col">{"Fecha de creación"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {rfqs.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Categoria}</td>
                        <td>{data.Descripcion}</td>
                        <td>{data.Fecha}</td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                         </ScrollArea>
                        </ModalBody>
                </Modal>



               




               <Col xl="3" className="xl-50 col-6 hospital-patients box-col-6">
                <Card className="o-hidden">
                  <CardBody>
                    <div className="hospital-widgets media">
                      <div className="media-body">
                        <div className="hospital-content">
                          <h3 className="d-inline-block f-w-600">{"Proveedores"}</h3>
                        </div>
                        <div className="flowers">
                          <div className="flower1 flower-primary"></div>
                          <div className="flower2 flower-primary"></div>
                          <div className="flower3 flower-primary"></div>
                        </div>
                      </div>
                    </div>
                </CardBody>
                <CardFooter>
               {contadorprov1.map(data => (
                <Col key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <h3 className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</h3>
                            <h3 className="mt-0 mb-0 f-w-600 pull-right"><span className="counter">{data.scorr}</span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">  
                            </div>
                        </div>
                        <Button className="btn-pill pull-right"  color="warning-gradien" onClick={openmodalurp}>{"Ver detalles"}</Button> 
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {contadorprov2.map(data => (
                <Col key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <h3 className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</h3>
                            <h3 className="mt-0 mb-0 f-w-600 pull-right"><span className="counter">{data.scorr}</span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">  
                            </div>
                        </div>
                        <Button className="btn-pill pull-right"  color="warning-gradien" onClick={openmodalacept}>{"Ver detalles"}</Button> 
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              {contadorprov3.map(data => (
                <Col key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <h3 className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</h3>
                            <h3 className="mt-0 mb-0 f-w-600 pull-right"><span className="counter">{data.scorr}</span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">  
                            </div>
                        </div>
                        <Button className="btn-pill pull-right" onClick={openmodalcat} color="warning-gradien">{"Ver detalles"}</Button> 
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
               </CardFooter>
              </Card>
              </Col>
              <Modal isOpen={modalurp} toggle={toggleurp} className="modal-body" size="lg" centered={true}>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"Nombre"}</th>
                          <th scope="col">{"Empresa"}</th>
                          <th scope="col">{"Correo"}</th>
                          <th scope="col">{"Categorias"}</th>
                          <th scope="col">{"Fecha de creación"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {proveedores.map(data => (
                        <tr >
                        <td>{data.Nombre}</td>
                        <td>{data.Empresa}</td>
                        <td>{data.Correo}</td>
                        {data.Categoria == "" ? (
                            <td>{"Sin categoria"}</td>
                                  ) : (
                            <td>{data.Categoria}</td>
                                  )}
                            <td>{data.Fecha}</td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                         </ScrollArea>
                        </ModalBody>
                </Modal>
                <Modal isOpen={modalacept} toggle={toggleacept} className="modal-body" size="lg" centered={true}>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa"}</th>
                          <th scope="col">{"Categorias"}</th>
                          <th scope="col">{"Fecha de creación"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {acept.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Fecha}</td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                         </ScrollArea>
                        </ModalBody>
                </Modal>

                <Modal isOpen={modalcat} toggle={togglecat} className="modal-body" size="lg" centered={true}>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"Categoria"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {cates.map((data, index) => (
                        <tr key={index} >
                        {data.Categoria == "" ? (
                            <td disabled={true}>{""}</td>
                                  ) : (
                            <td>{data.Categoria}</td>
                                  )}
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                         </ScrollArea>
                        </ModalBody>
                </Modal>





               </Row>  
         </Fragment>
    )
}

export default Reporte