import React, {useState,Fragment,useEffect} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Container, Row, Col, Card,CardHeader, CardBody, Media,Button, ButtonModal, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup,Input} from 'reactstrap'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import blog from "../../assets/images/blog/bienvenido.jpg";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConstructionIcon from '@mui/icons-material/Construction';
import {useSelector} from 'react-redux'
import { sassSmallChartOptions, sassSmallChartListener, sassUserChart, sassUserChartOptions, sassUserChartListener } from '../dashboard/chartsData/chartist'
import ChartistGraph from 'react-chartist';
import {sassSmallChart1, sassSmallChart2, sassSmallChart3, sassSmallChart4, sassSmallChart5 } from '../dashboard/chartsData/chartist'
import { toast } from 'react-toastify';
import axios from 'axios';
import SweetAlert from 'sweetalert2'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CategoryUnspc from '../widgets/CategoryUnspc'
import blogx2 from "../../assets/images/blog/noticia1.png";
import blogx3 from "../../assets/images/blog/noticia2.png";
import blogx5 from "../../assets/images/blog/noticia3.png";
import blogx6 from "../../assets/images/blog/noticia4.png";
import ScrollArea from "react-scrollbar";

const sas = [
  { id: 1,title:"Proveedores",total:"2,000",class:"primary",chartData:sassSmallChart1},
  { id: 2,title:"Compradores",total:"25",class:"secondary",chartData:sassSmallChart2},
  // { id: 4,title:"Total Revenue",total:"45,01,046",monthly:"40,00,00",weekly:"215",class:"warning",chartData:sassSmallChart4},
  // { id: 5,title:"Total Shipment",total:"50,000",monthly:"40,000",weekly:"215",class:"success",chartData:sassSmallChart5}
]
const steps = [
  {
      selector: '.step1',
      content: 'Este es el perfil de usuario',
  },
  {
      selector: '.step2',
      content: 'Este es el menu principal',
  },
  {
    selector: '.step3',
    content: 'Abre el menu y selecciona una opción, podrás encontrar un tutorial para cada una de las secciones',
}
]

const Bienvenida = () => {



  const [modalb, setModalb] = useState(false);
    
  const toggleb = () => {
    setModalb(!modalb)
    localStorage.setItem("isUser",null)
  }

// useEffect(() => {
    
// const LoadingMsg = () => (
 
//   <div>
//    <i className="fa fa-bell-o" ></i>  {"Ha iniciado sesión como:"}<strong className="ml-2">{"Comprador"}</strong>  
//   </div>
// ) 
    
// // if(localStorage.getItem("isUser") === "true"){
// //   setModalb(true)
// //   toast(<LoadingMsg />, {
// //     position: toast.POSITION.TOP_RIGHT,
// //     autoClose: 3000,
// //     });
// // }else{
// //   setModalb(false)
// // }

// }, []);




  const currentUser= useSelector(state => state.Common.currentUser)
  const [opentour,setopentour] = useState(false)
    const closeTour = () => {
        setopentour(false);
      };

      const tuto = () =>{
        setTimeout(() => {
          setopentour(true);
        }, 350);
      }

      const disableBody = target => disableBodyScroll(target);
      const enableBody = target => enableBodyScroll(target);

      
      const cotizaaqui = () => {
        window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`)

      }
      
      const vende = () => {
        window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/RFQSList`)

      }



      useEffect(() => {
        if(currentUser.role == "Proveedor"){
        document.getElementById('comprador').style.display = 'none';
        document.getElementById('Admin').style.display = 'none';

        }if(currentUser.role == "Comprador"){
          document.getElementById('proveedor').style.display = 'none';
          document.getElementById('cate').style.display = 'none';
          document.getElementById('catebo').style.display = 'none';
          document.getElementById('Admin').style.display = 'none';
        }
        if(currentUser.role == "Admin"){
          document.getElementById('proveedor').style.display = 'none';
          document.getElementById('cate').style.display = 'none';
          document.getElementById('catebo').style.display = 'none';
          document.getElementById('comprador').style.display = 'none';
          document.getElementById('Admin1').style.display = 'none';
          document.getElementById('Admin2').style.display = 'none';
        }
    }, );
  


      
  useEffect(() => { 
    if(currentUser.Categoria == ""){
      
    }else{
      document.getElementById('consin').style.display = 'none';
    }

  }, );


    const [modal, setmodal] = useState(false);
    const [modal2, setmodal2] = useState(false);
    const [modal3, setmodal3] = useState(false);
    const [modal4, setmodal4] = useState(false);
    const [modal5, setmodal5] = useState(false);
    const id = currentUser.id;
    const [nombreus, setnombreus] = useState ("");
    const [ape, setape] = useState ("");
    const [num, setnum] = useState ("");
    const [emp, setemp] = useState ("");
    const [catup, setcatup] = useState ([]);

   
   

    const toggle = () => {
      setmodal(false)
    }
    const toggle2 = () => {
      setmodal2(false)
    }
    const toggle3 = () => {
      setmodal3(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    }
    const toggle4 = () => {
      setmodal4(false)
    }
    const toggle5 = () => {
      setmodal5(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    }
       
    const edcat =() => {
      setmodal2(true)

      setTimeout(() => {
        setmodal2(false)
        setmodal3(true)
      }, 900);
    }

    const [options,setOptions] = useState("")
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [autocompleteValues, setAutocompleteValues] = useState([]);

  
  

   

    const buscar = () => {
      
      const cat = JSON.parse(localStorage.getItem('selectedc'))
     
      if(cat == null) {
        SweetAlert.fire({title:"No has seleccionado ninguna categoría", text:"Escribe en el campo para buscar una categoria ", icon:"error"});
      }else {
        
        cat.map(data =>{
          const opcion = data.code + " - " + data.esp
          setcatup(opciones=>[...opciones, opcion])  
          
        })
    
        catup.forEach(function(elemento, indice, array) {   
          
        })
       
       
     

      setmodal2(true)
      setTimeout(() => {
        
        setmodal2(false)
        setmodal5(true)
      }, 1800);
    }
    }

  

    const Guardarcat = () => {
     
      const todo = {
        "id":currentUser.id,
       "Nombre":currentUser.Nombre,
       "Apellido":currentUser.Apellido,
       "Empresa":currentUser.Empresa,
       "Telefono":currentUser.Telefono,
       "Categoria":catup.join(),
       "role":currentUser.role,
       "Correo":currentUser.Correo,
       "Contraseña":currentUser.Contraseña,
     }
      setmodal3(false)
      setmodal5(false)
      toast.error("Se recargara la pagina en breve");

     setTimeout(() => {
      axios.put("https://b2bhubapi.com:3001/pruebas/gc2", {
         Categoria:catup.join(),
         id:currentUser.id,
        }).then(res => {
          setmodal(false)
          SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente", icon:"success"}); 
          sessionStorage.setItem('currentuser', JSON.stringify(todo));
        })
  }, 500);

       setTimeout(() => {
        
        window.location.reload();
      }, 3000);

      
    }

     



    return (
      <Fragment>
        <Container fluid={true}>
       <Card>
         </Card>
       </Container>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
            <Container fluid={true}>
              
                <Row>
                  <Col xl="12" className="xl-100 box-col-12">
                  </Col>  
                    <Col xl="6 box-col-12">
                             <Card style={{width: "460px"}}>
                           <div className="blog-box blog-shadow" style={{height: "200px", width: "450px"}}>
                                <Media className="img-fluid" src={blog} alt=""  style={{height: "200px", width: "629px"}}/>
                                <div className="blog-details">
                                    <p className="digits">{"Última actualización 06/09/2022"}</p>
                                    <h4>{"Te damos la bienvenida a B2Buysel"}</h4>
                                </div>
                            </div>
                            
                        </Card>
                        
                        <Card id="Admin1">
                          <CardHeader>
                            <h5 className="m-t-5 mb-0 f-w-600">{"B2B News"}</h5>
                          </CardHeader>
                        <ScrollArea horizontal={true} vertical={true}>                        

                        <Card  style={{width: "560px"}}>
                            <div className="blog-box blog-list row">
                                <Col sm="5">
                                  <a href="https://b2bnegocios.net/tendencias-clave-para-la-cadena-de-suministros-en-2023/" target="_blank">
                                    <Media className="img-fluid sm-100-w" src={blogx2} alt="" />
                                    </a>
                                </Col>
                                <Col sm="7">
                                <a href="https://b2bnegocios.net/tendencias-clave-para-la-cadena-de-suministros-en-2023/" target="_blank">
                                    <div className="blog-details">
                                        <div className="blog-date digits"><span>{"2"}</span> {"de Enero 2023"}</div>
                                        <h6>{"Tendencias clave para la cadena de suministros en 2023"} </h6>
                                        <div className="blog-bottom-content">
                                            <ul className="blog-social">
                                                <li>{"Publicado por: B2B NEWS"}</li>
                                            </ul>
                                            <hr />
                                            <p className="mt-0">{""}</p>
                                        </div>
                                    </div>
                                    </a>
                                </Col>
                        </div>         
                        </Card>  
                        <Card style={{width: "560px"}}>
                            <div className="blog-box blog-list row">
                                <Col sm="5">
                                <a href="https://b2bnegocios.net/ordenes-de-compra-solo-un-formato-o-herramienta-vital-para-la-empresa/" target="_blank">
                                    <Media className="img-fluid sm-100-w" src={blogx3} alt="" />
                                    </a>
                                </Col>
                                <Col sm="7">
                                <a href="https://b2bnegocios.net/ordenes-de-compra-solo-un-formato-o-herramienta-vital-para-la-empresa/" target="_blank">
                                    <div className="blog-details">
                                        <div className="blog-date digits"><span>{"28"}</span> {"de Diciembre 2022"}</div>
                                        <h6>{"Órdenes de compra ¿sólo un formato o herramienta vital para la empresa?"} </h6>
                                        <div className="blog-bottom-content">
                                            <ul className="blog-social">
                                                <li >{"Publicado por: B2B NEWS"}</li>
                                            </ul>
                                            <hr />
                                            <p className="mt-0">{""}</p>
                                        </div>
                                    </div>
                                    </a>
                                </Col>
                            </div>
                        </Card>

                        <Card style={{width: "560px"}}>
                            <div className="blog-box blog-list row">
                                <Col sm="5">
                                <a href="https://b2bnegocios.net/6-proveedores-mas-buscados-en-mexico-2023/" target="_blank">
                                    <Media className="img-fluid sm-100-w" src={blogx5} alt="" />
                                    </a>
                                </Col>
                                <Col sm="7">
                                <a href="https://b2bnegocios.net/6-proveedores-mas-buscados-en-mexico-2023/" target="_blank">
                                    <div className="blog-details">
                                        <div className="blog-date digits"><span>{"22"}</span> {"de Diciembre 2022"}</div>
                                        <h6>{"Los 6 proveedores más buscados en México para el 2023"} </h6>
                                        <div className="blog-bottom-content">
                                            <ul className="blog-social">
                                                <li >{"Publicado por: B2B NEWS"}</li>
                                            </ul>
                                            <hr />
                                            <p className="mt-0">{""}</p>
                                        </div>
                                    </div>
                                    </a>
                                </Col>
                            </div>
                        </Card>   

                        <Card  style={{width: "560px"}}>
                            <div className="blog-box blog-list row">
                                <Col sm="5">
                                  <a href="https://b2bnegocios.net/negociacion-que-es-negociar-con-proveedores/" target="_blank">
                                    <Media className="img-fluid sm-100-w" src={blogx6} alt="" />
                                    </a>
                                </Col>
                                <Col sm="7">
                                <a href="https://b2bnegocios.net/negociacion-que-es-negociar-con-proveedores/" target="_blank">
                                    <div className="blog-details">
                                        <div className="blog-date digits"><span>{"16"}</span> {"de Diciembre 2022"}</div>
                                        <h6>{"Negociación ¿Qué es negociar con proveedores?"} </h6>
                                        <div className="blog-bottom-content">
                                            <ul className="blog-social">
                                                <li>{"Publicado por: B2B NEWS"}</li>
                                            </ul>
                                            <hr />
                                            <p className="mt-0">{""}</p>
                                        </div>
                                    </div>
                                    </a>
                                </Col>
                        </div>         
                        </Card>  
                    </ScrollArea>      
                    </Card> 
                    </Col>
                            

                    <Col xl="6 box-col-12">
                    <Card id="Admin" >
                  <CardBody >
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-danger"><img src={"Heart"} alt=""/><ConstructionIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h6 className="m-t-5 mb-0 f-w-600">{"ÁREA DE ADMINISTRACIÓN"}</h6>
                          <h6 className="m-t-5 mb-0 f-w-600">{"Crea, edita y administra el portal B2BuySel"}</h6>
                        </div>                       
                        </div>
                        </div>
                    </CardBody>
                    </Card>



                    <Card id="comprador" >
                      <Button className="btn-pill pull-right" color="success-gradien" onClick={tuto}>{"Tutorial"}</Button> 
                  <CardBody >
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-danger"><img src={"Heart"} alt=""/><LocalMallIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h6 className="m-t-5 mb-0 f-w-600">{"Gestiona los procesos de tu negocio."}</h6>
                          <h6 className="m-t-5 mb-0 f-w-600">{"Abastecimiento y ventas."}</h6>
                        </div>                       
                        <Button onClick={cotizaaqui} className="btn-pill pull-right" color="danger-gradien" size="lg" style={{height:"70px", width:"180px"}} >{"Cotiza Ahora"}</Button>
                        </div>
                        </div>
                    </CardBody>
                    </Card>
                    <Card id="proveedor" >    
                    <Button className="btn-pill pull-right" color="warning-gradien" onClick={tuto}>{"Tutorial"}</Button> 
                  <CardBody>
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-danger"><img src={"Heart"} alt=""/><BusinessCenterIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h6 className="m-t-5 mb-0 f-w-600">{"Gestiona los procesos de tu negocio."}</h6>
                          <h6 className="m-t-5 mb-0 f-w-600">{"Abastecimiento y ventas"}</h6>
                        </div>
                        <Button className="btn-pill pull-right" color="warning-gradien" size="lg" onClick={vende} style={{height:"70px", width:"180px"}} >{"Oferta ahora"}</Button>
                        </div>
                        </div>
                        
                    </CardBody>
                    {/* <Button className="btn-pill"  color="success-gradien"  onClick={tuto}>{"Tutorial"}</Button>            */}
                            <div id="consin" className="blog-box blog-list row">
                                 <Col sm="5">
                                <CardBody className="btn-showcase">
                <Button id="catebo" className="btn-pill" size="lg" color="primary" onClick={edcat}>{"Elegir categoría"}</Button>
                </CardBody>
                                </Col>
                                <Col sm="7">
                                    <div id="cate" className="blog-details">
                                        <h6>{"¿Quieres llegar a mas clientes?, elige una categoría de productos"} </h6>
                                        <div className="blog-bottom-content">                                            
                                        </div>
                                    </div>
                                    
                                    
                                </Col>
                                </div>
                                <Modal isOpen={modal2} toggle2={toggle2} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                        </Modal>
                        <Modal isOpen={modal3} toggle3={toggle3} size="lg" style={{width:'2000px'}}  centered>
                        <ModalHeader toggle={toggle3}>{"Categorías disponibles"}
                        </ModalHeader>
                        <ModalBody>
                       <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle3}>{"Cancelar"}</Button>
                        <Button color="primary" onClick={buscar}>{"Siguiente"}</Button> 
                        </ModalFooter>
                    </Modal>
                   
                        <Modal isOpen={modal5} toggle5={toggle5} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <h4 className="mb-4">{"Estás seguro de guardar los siguientes cambios: "}</h4>
                        <h6 className="mb-1">{catup.join()}</h6>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle5}>{"cancelar"}</Button>
                        <Button color="secondary" onClick={Guardarcat}>{"Guardar"}</Button>
                        </ModalFooter>
                    </Modal>
                                
                                
                   
            
                           
                        </Card>
                      <Card id="Admin2">
                      <CardHeader>
                                    <h5>{"Conoce también"} </h5>
                                </CardHeader>
                      <CardBody>
                     
                                    <div id="aniimated-thumbnials3" className="row gallery my-gallery">
                                        <figure className="img-hover hover-4">
                                        <a href="https://b2bnegocios.net/entorno/#b2bexpo/" target="_blank" >
                                                <Media style={{ width: "70px", position: "relative", left: "92.9904px"}}src={require('../../assets/images/imghover/expo.png')} itemProp="thumbnail"
                                                 alt=""/>
                                                 </a>
                                        </figure>
                                        <figure className="img-hover hover-4">
                                        <a href="https://b2bnegocios.net/entorno/hub2b/" target="_blank" >
                                                <Media style={{ width: "70px", position: "relative", left: "203px"}}src={require('../../assets/images/imghover/match.png')} itemProp="thumbnail"
                                                 alt=""/>
                                                 </a>
                                        </figure>
                                        <figure  className="img-hover hover-4">
                                        <a href="https://b2bnegocios.net/b2b-news/" target="_blank" >
                                                <Media  style={{ width: "70px", position: "relative", left: "322.989px"}} src={require('../../assets/images/imghover/news.png')} itemProp="thumbnail"
                                                 alt=""/></a>
                                        </figure>
                                    </div>

                                   
                                </CardBody>
                        </Card>       
                        </Col> 

                   
                 
                       
           

                </Row>
                <Modal  isOpen={modalb} className="welcome-popup modal-dialog-centered ">
            <button  onClick={toggleb} className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <ModalBody>
              <ModalHeader></ModalHeader>
              <div className="contain p-50">
                <div className="text-center">
                  <h3>{"Bienvenido a B2Buysel"}</h3>
                  <p>{"..."} </p>
                  <button 
                    onClick={toggleb}
                    className="btn btn-primary btn-lg txt-white" 
                    type="button" data-dismiss="modal" 
                    aria-label="Close">{"Comienza Ahora"}</button>
                </div>
              </div>
            </ModalBody>
          </Modal>

            </Container>            
        </Fragment>
    )
                        }
                        
export default Bienvenida;
