import React, {useState,Fragment} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Media, Container, Row, Col, Card, CardBody,CardHeader, Table,Button, ButtonModal, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup} from 'reactstrap'
import { RFQS, RFQ, } from '../../constant';
import axios from 'axios';
import { useEffect } from 'react';
import {Link} from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { toast } from 'react-toastify';
import {useSelector} from 'react-redux'
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import DSH from "../../assets/images/blog/DASHBOARD3.png";
import GBN from "../../assets/images/gbn_logo.jpg";
import ofr from '../../assets/images/ofr.png'
import ScrollArea from "react-scrollbar";
import emailjs from 'emailjs-com';

const steps = [
  {
      selector: '.ep1',
      content: 'Esta es la lista de cotizaciones creadas por nuestros compradores',
  },
  {
      selector: '.ep2',
      content: 'Pulsa este boton para ver los detalles, asignar un precio y enviar una oferta',
  },
  {
    selector: '.ep3',
    content: 'Conoce tambien nuestro portal de cotizaciones con una demanda externa',
},
{
  selector: '.ep4',
  content: 'Acepta un RFQ y conoce COTIZA',
}
]
const RFQSList = () => {

  //tour
  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

//lo demas

  const currentUser= useSelector(state => state.Common.currentUser)


 const [modale, setModale] = useState();


 


const URL ="https://b2bhubapi.com:8000/pruebas/tablarfqs"
const getData = async () =>{
  const response = axios.get(URL);
  console.log(response);
  return response;
  
}


  const [list, setlist] = useState([])

  useEffect(() => {
    getData().then(( response ) =>{
      setlist(response.data);
  
    })
  }, [])

  const [seglist, setseglist] = useState([])



  useEffect(()=>{
    const categorias = currentUser.Categoria.split(',');
    if(categorias.length > 1){
      let intoapi = []
      for (let i = 1; i < categorias.length; i++){
        intoapi.push(" OR (INSTR(Categoria," + "'"+ categorias[i] + "'" + ") > 0)") 
      }    
    axios.get('https://b2bhubapi.com:5050/pruebas/proveedores', {
    params: {
      foo: "SELECT * from B2BUYSEL.RFqsbuysel WHERE " + "(INSTR(Categoria," + "'" + (categorias[0]) + "'" + ") > 0)" + intoapi.join("")
    }}      
    ).then((response) => {
        setseglist(response.data);
      });
    }
    if(categorias.length == 1){
       axios.get('https://b2bhubapi.com:5050/pruebas/proveedores', {
    params: {
      foo: "SELECT * from B2BUYSEL.RFqsbuysel WHERE " + "(INSTR(Categoria," + "'" + (categorias[0]) + "'" + ") > 0)" 
    }}      
    ).then((response) => {
        setseglist(response.data);
      });
    }
},[])



  
    const notificar = (datos) =>{ //llamar api para datos, conseguir email del creador del rfq
      console.log('notificar a', datos) //aqui es donde se manda el email al creador del RFQ notificandole que alguien acepto su RFQ
    }
    const [seleccionado, setseleccionado] = useState({
        RFQsl:'',
        Empresa_Solicitante:'',
        Descripcion:'',
        Cantidad_Solicitada:'',

        Fechavigencia:'',
        Estatussl:'',
        iduc:''
        
    });
    const [pre, setpre] = useState ("");
    const [cargador, setcargador] = useState ("");
    const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido)
      var el = hoy.getDate().toString();
      if (el.length == 1){ //example if 1 change to 01
      el = "0"+ el;
      }
      var getMonth = (hoy.getMonth()+1).toString();
      if (getMonth.length == 1){
      getMonth = "0"+getMonth;
      }
      var getYear = hoy.getFullYear().toString();
      var fecha = getYear + "-" + getMonth + "-" + el
      var hora = hoy.getHours() + ':' + hoy.getMinutes();
 
    const enviar = () => { 
      if(pre == ""){
        SweetAlert.fire({title:"No has asignado un precio a la cotización", text:"Asigna un precio para entrar a la oferta.", icon:"error"});
      }else{
        setcargador(true)
        axios.post('https://b2bhubapi.com:3001/pruebas/aceptarprov', 
      
        {RFQsl:seleccionado.RFQsl,
        Empresa_solicitante:seleccionado.Empresa_solicitante,
        Categoria:seleccionado.Categoria,
        Descripcion:seleccionado.Descripcion,
        Cantidad_solicitada:seleccionado.Cantidad_Solicitada,
        Unidadmedida:seleccionado.Unidadmedida,
        Precioobj:pre,
        diasdecredito:seleccionado.diasdecredito, 
        Moneda:seleccionado.Moneda, 
        Fechavigencia:seleccionado.Fechavigencia, 
        lugaentrega:seleccionado.lugaentrega, 
        Estatussl:seleccionado.Estatussl,
        idui:currentUser.id,
        iduc:seleccionado.iduc,
        Fechadecreacion:fecha,
        }).then((response) => {
          axios.get('https://b2bhubapi.com:3001/pruebas/usuariosanotificar2', { 
   params: {
    foo: seleccionado.iduc
  }}    
          ).then((response) => {
            var usuariosanotificar = response.data
            usuariosanotificar.forEach(usuario=>{
              var params = {
                fromName:currentUser.Nombre, 
                toName:usuario.Nombre, 
                toEmail:usuario.Correo,
               
              }
                emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
                  setcargador(false)
                  SweetAlert.fire({title:"Completado", 
                  text:"Has aceptado este RFQ, se ha enviado un Email al comprador, espera una respuesta pronto", 
                  icon:"success",
                  confirmButtonText: "Ok",}).then(async(result) => {
                    if(result.isConfirmed == true){
                      window.location.reload();  
                    }
                  });
                })
            })
    });
      });
      setModale(!modale)
      }
    };

    
  const [rfqs, setrfqs] = useState([])
    useEffect(()=>{
      axios.get('https://b2bhubapi.com:5050/pruebas/provmisacep', {
        params: {
          foo: currentUser.id
        }}      
        ).then((response) => {
            if(response.data.length == 1){
              setrfqs(response.data[0].RFQsl)
            }
            if(response.data.length > 1){
              for (let i = 0; i < response.data.length; i++){
                rfqs.push(response.data[i].RFQsl)
              }
            }
          });
    
    },[])

  const toggle = (data) => {
    let esono = rfqs.find(c => c == data.RFQsl)
  if(esono == null){
    setseleccionado(data)
    setModale(!modale)
  }if(esono.length >= 1){
    SweetAlert.fire({title:"Ya ha respondido esta cotización", text:"Su propuesta esta siendo analizada por el creador de la cotización", icon:"error"});
  }
   }
    return (
      <Fragment>
         <Card>
                <CardHeader>
                <img className="pull-left" src={DSH} style={{height: "35px"}} alt=""/>
                <Button className="btn-pill pull-right" color="warning-gradien" size="lg" onClick={tuto} >{"Tutorial"} </Button>
                </CardHeader>
              </Card>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
            <Container fluid={true}>
                <Row>
                  <Col xl="12" className="xl-100 box-col-12">
                    <Card className="widget-joins">
                      <Row>

                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <div className="user-status table-responsive">
                      <Table className="ep1">                      
                        <thead>
                          <tr>
                            <th scope="col">{"RFQ"}</th>
                            <th scope="col">{"RFQ invitado"}</th>
                            <th scope="col">{"Empresa solicitante"}</th>
                            <th scope="col">{"Producto solicitado"}</th>
                            <th scope="col">{"Cantidad solicitada"}</th>
                            <th scope="col">{"Fecha de solicitud"}</th>
                            <th scope="col">{"Estado"}</th>
                            <th scope="col">{"Acciones"}</th>
                          </tr>
                        </thead>
                        <tbody>                      
                        {seglist.map(data => (
                        <tr >
                         <td>{data.RFQsl}</td>
                         <td>{"Propuesta abierta para todo el público"}</td>
                         <td>{data.Empresa_solicitante}</td>
                         <td>{data.Descripcion}</td>
                         <td className='text-center'>{data.Cantidad_Solicitada}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         <td className={`ep2`} ><Button  onClick={() => toggle(data)} >{"Ofertar"}</Button></td> 
                         <Modal isOpen={modale} className="modal-lg modal-dialog-centered product-modal"  centered={true}>
                         <ModalHeader className="d-inline-block f-w-600" >{'Ofertar precio de producto'}
                        </ModalHeader>
                        <ModalBody>
                        <div className="product-box row">
                        <Col lg="6" className="product-details  text-left" style={{position: "relative",left: "9.5px"}}>
                        <div className="product-view">
                          <h5 className="d-inline-block f-w-600">{"Detalles de la cotización"}</h5>
                        <ScrollArea horizontal={true} vertical={true}>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.RFQsl} />
                        </FormGroup>
                        <FormGroup>
                        <label> Categoría: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Estado </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado && seleccionado.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        </ScrollArea>
                        </div>
                         </Col> 
                         <Col lg="6" className="product-box row">
                        <label> Precio objetivo: </label>
                        <input className="form-control" type="number"  placeholder="$" onChange= {(e) => {setpre(e.target.value)}} />
                         <Media className="img-fluid" src={ofr} alt=""/>
                         </Col>
                         </div>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{'Cerrar'}</Button>
                        <Button color="secondary" onClick= {enviar}>{'Aceptar y enviar'}</Button> 
                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                      ))}                         
                        {list.map(data => (
                        <tr>
                         {/* <td>{data.RFQ}</td>
                         <td>{data.RFC_Invitado}</td>
                         <td>{data.Empresa_Solicitante}</td>
                         <td>{data.Producto_Solicitado}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Fecha_Solicitud}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatus}</div>
                         </td>
                         <td><a href="http://b2bcotiza.com/Acceso" target="_blank" ><Button  >{"Aceptar"} {RFQ}</Button></a></td> */}
                      </tr>   
                        ))}                          
                    </tbody>
                      </Table>
                    </div>

                  </CardBody>
              </Card>
              <Modal isOpen={cargador}  size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
              <Card>
                <CardHeader className='ep3' >
                <img className="pull-left" src={GBN} style={{height: "35px"}} alt=""/>
                </CardHeader>
                <CardBody>
                <Table>                      
                        <thead>
                          <tr>
                            <th scope="col">{"RFQ"}</th>
                            <th scope="col">{"RFQ invitado"}</th>
                            <th scope="col">{"Empresa solicitante"}</th>
                            <th scope="col">{"Producto solicitado"}</th>
                            <th scope="col">{"Cantidad solicitada"}</th>
                            <th scope="col">{"Fecha de solicitud"}</th>
                            <th scope="col">{"Estado"}</th>
                            <th scope="col">{"Acciones"}</th>
                          </tr>
                        </thead>
                        <tbody>                                        
                        {list.map(data => (
                        <tr>
                         <td>{data.RFQ}</td>
                         <td>{data.RFC_Invitado}</td>
                         <td>{data.Empresa_Solicitante}</td>
                         <td>{data.Producto_Solicitado}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Fecha_Solicitud}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatus}</div>
                         </td>
                         <td ><a  href="https://b2bnegocios.net/integra/cotiza/" target="_blank" ><Button className='ep4' >{"Ofertar"}</Button></a></td>
                      </tr>   
                        ))}                          
                    </tbody>
                      </Table>

                </CardBody>
              </Card>


            </Container>            
        </Fragment>
    )
                        }
                        
export default RFQSList;
