import React, {useState,Fragment, useEffect } from 'react'
import { iframe,Container, Row, Col, Card, CardBody, CardHeader, Button,Label, Table, CardFooter, input, form, Input,Modal, ModalHeader, ModalBody, ModalFooter,Form,FormGroup,img} from 'reactstrap'
import graph from '../../assets/images/dashboard-sass/logo.png'
import LoadingButton from '@mui/lab/LoadingButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Buton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './css/sourcesanspro-font.css'
import './css/style.css'
import banner from './form-v8.png'
import PropTypes from 'prop-types';
import footer from './footer.png'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import b2b from '../../assets/images/dashboard-sass/b2b.png'
import Checkbox from '@mui/material/Checkbox';
import man from '../../assets/images/dashboard/user.png';
import ScrollArea from "react-scrollbar";
import {firebase_app} from '../../data/config'
import Check from '@mui/icons-material/Check';
import {handleResponse} from  '../../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {useHistory, Link } from 'react-router-dom';
import Axios from 'axios';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useDispatch } from 'react-redux';
import SweetAlert from 'sweetalert2'
import { styled } from '@mui/material/styles';
import { RFC_2822 } from 'moment/moment';

const Loginn = () => {

	
    
	//TOGGLE DE LOS BOTONES DE INICIO
 
	const [loc, setloc] = useState(true);
	useEffect(() => {
		if(loc == true){
		document.getElementById('sign-in').style.display = 'none';
		const tablinks = document.getElementById("Iniciar");
		tablinks.className = "tablinks active"
		setloc(false)
		}
		}, );

	const iniciar = ()  => {
		document.getElementById('sign-up').style.display = 'block';
		document.getElementById('sign-in').style.display = 'none';
		const tablinks = document.getElementById("Inscribirse");
	    tablinks.className = "tablinks"
		const tablinks2 = document.getElementById("Iniciar");
	    tablinks2.className = "tablinks active"
	}
	const inscribirse = ()  => {
		document.getElementById('sign-up').style.display = 'none';
		document.getElementById('sign-in').style.display = 'block';
		const tablinks = document.getElementById("Iniciar");
	    tablinks.className = "tablinks"
		const tablinks2 = document.getElementById("Inscribirse");
	    tablinks2.className = "tablinks active"
	}

	//PROCESO DE AUTENTICACIÓN
	
	const dispatch = useDispatch();
    const history = useHistory();
    const {loginWithRedirect} = useAuth0()
	const [loading,setLoading] = useState(false) 
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
      localStorage.getItem('Name')
    );
      const [isuser, setisuser] = useState(localStorage.getItem("isUser"));
    

	const loginAuth = async (event) => {
        if(checked == false){   
          SweetAlert.fire({title:"No ha aceptado los términos y condiciones", text:"Acepta para continuar", icon:"info"});
        }if(checked == true){
          Axios.post('https://b2bhubapi.com:8080/pruebas/login',
          {correo: email,
          password: password,}
        ).then((response) => {
           if (response.data.message){
            setTimeout(() => {
              setValue(man);
          SweetAlert.fire({title:"Correo o contraseña incorrectos", text:"intentalo nuevamente", icon:"error"});
          }, 500);
           }
         else{
             sessionStorage.setItem('currentuser', JSON.stringify(response.data[0]))
    
             localStorage.removeItem('token')
             const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: ({ email:"", password:""})
            };
            return fetch('/users/authenticate', requestOptions)
            .then(handleResponse)
            .then(user => {
               setValue(man);
               setisuser("true");
               setTimeout(()=>{
                window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
                window.location.reload();
               }, 500)
              return user;
            });
           }
        });
        }
      }

	  //PROCESO DE REGISTRACIÓN
	  const [correoreg, setcorreoreg] = useState("");
  const [numeroreg, setnumeroreg] = useState("");
  const [nombrereg, setnombrereg] = useState("");
  const [apellidoreg, setapellidoreg] = useState("");
  const [empresareg, setempresareg] = useState("");
  const [passwordreg, setpasswordreg] = useState("");
  const [password2reg, setpassword2reg] = useState("");
  const [rolereg, setrolereg] = useState("");
  const [categoriareg, setcategoriareg] = useState ("");
  const [tp, settp] = useState("")
  const [rfc, setrfc] = useState("")
  

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido)
  var el = hoy.getDate().toString();
  if (el.length == 1){ //example if 1 change to 01
  el = "0"+ el;
  }
  var getMonth = (hoy.getMonth()+1).toString();
  if (getMonth.length == 1){
  getMonth = "0"+getMonth;
  }
  var getYear = hoy.getFullYear().toString();
  var fecha = getYear + "-" + getMonth + "-" + el ;
  var hora = hoy.getHours() + ':' + hoy.getMinutes();
  const fechaYHora = 'Fecha: ' + fecha + ' ' + 'Hora: ' + hora;
   
  const registro = () => {
    if(checked == false){   
      SweetAlert.fire({title:"No ha aceptado los términos y condiciones", text:"Acepta para continuar", icon:"info"});
    }if(checked == true){
    if(numeroreg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(nombrereg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(apellidoreg == ""){ 
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(empresareg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(rolereg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(correoreg == ""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    if(passwordreg ==""){
      SweetAlert.fire({title:"Llena los datos correctamente", text:"intentalo nuevamente", icon:"error"});
    }
    else{
      if(passwordreg!==password2reg){
        SweetAlert.fire({title:"la contraseña no coincide o es incorrecta", text:"Intenta nuevamente", icon:"error"});
      }
      else{
          
      }
      
    }
  }
};

const [modal, setmodal] = useState(false);

const toggle = () =>{
     setmodal(false)
}

const tyc = () => {
  setmodal(true)
}



const refforget = () => {
	window.location.href = (`${process.env.PUBLIC_URL}/#/Olvidastetucontraseña`)
  }
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const Acepto = () =>{
      setChecked(true)
      setmodal(false)
  }
  

const [apirespuesta, setapirespuesta] = useState([])
const [muni, setmuni] = useState("")
const [est, setest] = useState("")
const [cp, setcp] = useState("")
const [calle, setcalle] = useState("")
const [colonia, setcolonia] = useState("")
const [completo, setcompleto] = useState("")
const [nume, setnume] = useState("")

const [charging, setcharging] = useState(false);
function handleClick() {
  setcharging(true);
  consultacp()
}

  const steps = [
    {
      label: 'Propocionanos información de tu empresa',
      description: `For each ad campaign that you create, you can control how much`,
      h:
      <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
      <InputLabel color="error" id="demo-simple-select-standard-label" 
                required>Selecciona la función a desempeñar</InputLabel>
      <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => {setrolereg(e.target.value)}}
                 >
                <MenuItem disabled value="">
                 <em>Ninguno</em>
                </MenuItem>
                <MenuItem value={"Comprador"}>Comprador</MenuItem>
                <MenuItem value={"Proveedor"}>Proveedor</MenuItem>
                </Select>
                <div>
                  <h6>i</h6>
                </div>
                <Label style={{color: "rgb(124, 118, 118)"}}> {'Tipo de persona (SAT)'}</Label>
                <Input onChange={(e) => {settp(e.target.value)}} type="select" className="custom-select text-align-right" >
                   <option >{""}</option>
                      <option >{"Persona Física"}</option>
                      <option >{"Persona Moral"}</option>
                </Input>
      </FormControl>
    },
    {
      label: 'Proporcionanos información de tu perfil',
      description:
        'An ad group contains one or more ads which target a shared set of keywords.',
        h:
        <label class="form-row-inner">
        <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
        <TextField onChange= {(e) => {setrfc(e.target.value)}} placeholder='RFC' label="RFC" className="form-control" color="error"  variant="standard"/>
        <TextField  disabled={tp === "Persona Física"} onChange= {(e) => {setempresareg(e.target.value)}} placeholder='Empresa' label="Empresa" className="form-control" color="error"  variant="standard"/>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
        <TextField  onChange= {(e) => {setnombrereg(e.target.value)}} placeholder='Nombre' label="Nombre" className="form-control" color="error"  variant="standard"/>
        <TextField onChange= {(e) => {setapellidoreg(e.target.value)}} placeholder='Apellido' label="Apellido" className="form-control" color="error"  variant="standard"/>
        
        <TextField onChange= {(e) => {setnumeroreg(e.target.value)}}  inputMode="numeric" pattern="[0-9]{10}" placeholder='Teléfono' label="Teléfono" className="form-control" color="error"  variant="standard"/>
        </Box>
        </label>
    },
    {
      label: 'Proporcionanos tu dirección',
      description: `Try out different ad text to see what brings in the most customer`,
      h:<label class="form-row-inner">
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <TextField  onChange= {(e) => {setcp(e.target.value)}} placeholder='Código postal' label="Código postal" className="form-control" color="error"  variant="standard"/>
      <TextField onChange= {(e) => {setcalle(e.target.value)}} placeholder='Calle' label="Calle" className="form-control" color="error"  variant="standard"/>
      <TextField onChange= {(e) => {setnume(e.target.value)}} placeholder='Número #' label="Número #" className="form-control" color="error"  variant="standard"/>
      </Box>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
      <InputLabel color="error" id="demo-simple-select-standard-label" 
                required>Selecciona una colonia</InputLabel>
      <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => {setcolonia(e.target.value)}}
                 >
                  <MenuItem disabled value="">
                 <em>Ninguno</em>
                </MenuItem>
                  {apirespuesta.map((data, index) => (
                                <MenuItem value={data.Asentamiento}>
                                {data.Asentamiento}
                                </MenuItem>
                                 ))}
                </Select>
                </FormControl>
        <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
        <TextField  disabled={true} placeholder={muni} label={muni} className="form-control" color="error"  variant="standard"/>
        <TextField  disabled={true} placeholder={est} label={est} className="form-control" color="error"  variant="standard"/>
        </Box>        
        <LoadingButton
                  onClick={handleClick}
                  loading={charging}
                    sx={{ mt: 1, mr: 1 }}
                    color="error"
                    id="cp"
                  >
                    consultar
                  </LoadingButton>
      </label>

    },
    {
      label: 'Proporcionanos tu correo y crea una contraseña',
      description: `Try out different ad text to see what brings in the most customer`,
      h:<label class="form-row-inner">
        <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
        <MailOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField pattern="[^@\s]+@[^@\s]+" title="Email invalido"  onChange= {(e) => {setcorreoreg(e.target.value)}} placeholder='E-mail' label="E-mail" className="form-control" color="error"  variant="standard"/>
        </Box>
      <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
      <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
      <TextField onChange= {(e) => {setpasswordreg(e.target.value)}} placeholder='Contraseña' label="Contraseña" type="password" className="form-control" color="error"  variant="standard"/>
      <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
      <TextField  onChange= {(e) => {setpassword2reg(e.target.value)}} placeholder='Verificar contraseña' label="Verificar contraseña" type="password" className="form-control" color="error"  variant="standard"/>     </Box>
      </label>
    },
    {
      label: 'Acepto los términos y condiciones del servicio',
      description: `Try out different ad text to see what brings in the most customer`,
      h:<label class="form-row-inner">
        <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
                 <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    /><Label className="btn-link text-capitalize" style={{cursor:'pointer',color:"#003153",position: "relative",left: "-8.797px"}} onClick={tyc} for="tm">{'Aceptar términos y condiciones'}</Label>  
                 </Box>
      </label>
    },
  ];
  

  

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#cc0b0a',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#cc0b0a',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#cc0b0a',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#cc0b0a',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#cc0b0a',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#cc0b0a',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));
  
  function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  function ValidateRoleandtp()
{
    // Validate ROLE
    if (rolereg =="Comprador" || rolereg=="Proveedor") { 
      var validate1 = true
    }else{
    SweetAlert.fire({title:"La función a desempeñar es un dato obligatorio", text:" selecciona una función e intentalo nuevamente", icon:"error"});
  }
  // validate tipo de persona
    if (tp =="" || tp ==null) { 
      SweetAlert.fire({title:"El tipo de persona es un dato obligatorio", text:"selecciona un tipo de persona e intentalo nuevamente", icon:"error"});
    }else{
      var validate2 = true
    }
    if(validate1 == true && validate2 == true){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  return false;
}
function Validaterfc()
{
  // validate tipo de persona
     var paramspm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$"
     var paramspf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
     "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$"
     var paramsn = "^[a-zA-Z][a-zA-Z\s]{0,15}[a-zA-Z]$"
     var paramsc = "^[A-Z]([a-zA-Z0-9]|[- @\.#&!]){3,50}$"
     var paramst = "^[0-9]{10,11}$"
     if(numeroreg == "" || numeroreg == null){
      SweetAlert.fire({title:"Coloca un número de teléfono", text:"Ingresa un número de teléfono e intentalo nuevamente", icon:"error"});
    }else{
      if (numeroreg.match(paramst)){
        var vt = true;
      }else {
        SweetAlert.fire({title:"Número de teléfono invalido", text:"El número de teléfono consiste de 10 a 11 numeros sin espacios ingresa un número de teléfono e intenta nuevamente", icon:"error"});
      }
    }
    if(apellidoreg == "" || apellidoreg == null){
      SweetAlert.fire({title:"Coloca un apellido", text:"Ingresa un apellido e intentalo nuevamente", icon:"error"});
    }else{
      if (apellidoreg.match(paramsn)){
        var va = true;
      }else {
        SweetAlert.fire({title:"Apellido invalido", text:"Coloca un apellido valido", icon:"error"});
      }
    }
    if(nombrereg == "" || nombrereg == null){
      SweetAlert.fire({title:"Coloca un nombre de usuario", text:"Ingresa un nombre de usuario e intentalo nuevamente", icon:"error"});
    }else{
      if (nombrereg.match(paramsn)){
        var vn = true;
      }else {
        SweetAlert.fire({title:"Nombre invalido", text:"Escribe tu nombre adecuadamente, valido un solo nombre", icon:"error"});
      }
    }
    if(tp == "Persona Física"){
      setempresareg("Persona Física")
      var vem = true;
    }if (tp == "Persona Moral"){
      if(empresareg == "" || empresareg == null){
        SweetAlert.fire({title:"Coloca tu empresa", text:"El nombre de la empresa debe contener una mayúscula al inicio, corrigela e intentalo nuevamente", icon:"error"});
      }else{
        if (empresareg.match(paramsc)){
          var vem = true;
        }else {
          SweetAlert.fire({title:"Error nombre de empresa incorrecto", text:"Ingresa un nombre de empresa valido", icon:"error"});
        }
      }
    }

    if(rfc =="" || rfc ==null) { 
      SweetAlert.fire({title:"Coloca un RFC", text:"Coloca tu RFC e intentalo nuevamente", icon:"error"});
    }
    else{
      if(tp == "Persona Física"){
        if (rfc.match(paramspf)){
          var vrfc = true;
        }else {
          SweetAlert.fire({
            icon:"error",
            text:"El formato RFC de una persona Física consiste de 13 carácteres alfanúmericos en mayúsculas, para mas información consulta tu RFC",
            title:"Error en el formato de RFC",    
            confirmButtonText: "Consulta tu RFC aquí",
            cancelButtonText:"Intenta nuevamente",
            confirmButtonColor:"#416788",
            cancelButtonColor: "#6596CC",
            reverseButtons: true,
            showCancelButton: true,
          }).then(async(result) => {
            if(result.isConfirmed == true){
               var url = "https://www54.sat.gob.mx/curp/Consult/"
               window.open(url, '_blank');
            }
          });
        }
      }
      if(tp == "Persona Moral"){
        if (rfc.match(paramspm)){
          var vrfc = true;
        }else {
          SweetAlert.fire({
            icon:"error",
            text:"El formato RFC de una persona Moral consiste de 12 carácteres alfanúmericos en mayúsculas, para más información consulta tu RFC",
            title:"Error en el formato de RFC",    
            confirmButtonText: "Consulta tu RFC aquí",
            cancelButtonText:"Intenta nuevamente",
            confirmButtonColor:"#416788",
            cancelButtonColor: "#6596CC",
            reverseButtons: true,
            showCancelButton: true,
          }).then(async(result) => {
            if(result.isConfirmed == true){
               var url = "https://www54.sat.gob.mx/curp/Consult/"
               window.open(url, '_blank');
            }
          });
        }
      }
    }
    
    if(vrfc == true && vn == true && va == true && vem == true && vt == true){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    console.log({
      "1":va,
      "2":vrfc,
      "3":vn,
      "4":vt,
      "5":vem,
    })
    
  return false;
}
function Validatedire()
{
  var paramcp = "^[0-9]{5}$"
  var paramcl = "^([a-zA-Z0-9]|[- @\.#&!]){3,50}$"
  if(cp == "" || cp == null){
    SweetAlert.fire({title:"Coloca un código postal", text:"Ingresa un código postal e intentalo nuevamente", icon:"error"});
  }else{
    if (cp.match(paramcp)){
      var vcp = true;
    }else {
      SweetAlert.fire({title:"Coloca un código postal", text:"El número de código postal consiste de 5 digitos, ingresa uno e intentalo nuevamente", icon:"error"});
    }
  }
  if(calle == "" || calle == null){
    SweetAlert.fire({title:"Coloca una calle", text:"Ingresa un calle e intentalo nuevamente", icon:"error"});
  }else{
    if (calle.match(paramcl)){
      var vcl = true;
    }else {
      SweetAlert.fire({title:"Coloca un calle valida", text:"La calle nos es valida, ingresa e intentalo nuevamente", icon:"error"});
    }
  }
  if(nume == "" || nume == null){
    SweetAlert.fire({title:"Coloca un número", text:"Ingresa un número e intentalo nuevamente", icon:"error"});
  }else{
    var vn = true
  }
  if(colonia == "" || colonia == null){
    SweetAlert.fire({title:"Selecciona una colonia", text:"Ingresa un código postal, pulsa el boton consultar e intentalo nuevamente", icon:"error"});
  }else{
    var col = true;
  }
  if(vcp == true && vcl == true && col == true && vn == true){
    setcompleto(calle + ", " + nume + ", " + colonia + ", " + muni + ", " + est)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
    
  return false;
}

 function consultacp (){
  Axios.get('https://b2bhubapi.com:4545/codigo_postal', {
    params: {
      foo: cp
    }
  }      
    ).then((response) => {
      if(response.data.length == 0){
        SweetAlert.fire({title:"ERROR", text:"Coloca un codigo postal valido", icon:"error"});
        setcp("")
        setcharging(false)    
}else{
  setapirespuesta(response.data)
  setmuni(response.data[0].Municipio)
  setest(response.data[0].Estado)
  setcharging(false)
}
    })
  }

  function Validatecyp()
  {
    var paramcor = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var paramcon = "^(?=[A-Z]{1})(?=[^!@#$&_*]*[!@#$&_*])(?=(?:[^0-9]*[0-9]*[0-9]){3}).{8,}$"


      if(passwordreg == "" || password2reg =="" && passwordreg == "" && password2reg == ""){
        SweetAlert.fire({title:"La contraseñas no pueden quedar vacias", text:"Escribe y confirma tu contraseña e intenta nuevamente", icon:"error"});
      }
      else{
        if( passwordreg == password2reg && password2reg.match(paramcon) && passwordreg.match(paramcon)){
                    var valcon = true;
        }else{
          SweetAlert.fire({title:"Contraseña no valida", text:"La contraseña debe tener 8 o más carácteres y el siguiente formato: 1.-Al menos una mayúscula, 2.-Al menos una minúscula, 3.-Al menos 3 números, 4.-Al menos un carácter especial", icon:"error"});
        }

    }

      if (correoreg =="" || correoreg ==null) { 
        SweetAlert.fire({title:"El correo no puede quedar vacio", text:"Escribe tu correo e intentalo nuevamente", icon:"error"});
      }else{
        if(correoreg.match(paramcor)){
          var valcor = true;
        }else{
          SweetAlert.fire({title:"Correo no valido", text:"Escribe tu correo con el siguiente formato: ejemplo@b2buysel.com", icon:"error"});
        }
      }
      if(valcon == true && valcor == true){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    return false;
  }

  function Validatetyc(){
    if(checked == false){   
      SweetAlert.fire({title:"No ha aceptado los términos y condiciones", text:"Acepta para continuar", icon:"info"});
    }if(checked == true){
      
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      Axios.post('https://b2bhubapi.com:8080/pruebas/registro',
                {correo: correoreg, 
                password: passwordreg,
                numero: numeroreg,
                nombre: nombrereg,
                primerapellido:apellidoreg,
                empresa: empresareg,
                role: rolereg,
                categoria: categoriareg,
                Tipo_persona: tp,
                RFC: rfc,
                Direccion: completo,
                Fechadecreacion: fecha,
                Hora: hora,
          
              }).then(async(response) => {                
                    Axios.post('https://b2bhubapi.com:8080/pruebas/login',
                    {correo: correoreg,
                    password: passwordreg,}, 
                  ).then((response) => {
                    sessionStorage.setItem('currentuser', JSON.stringify(response.data[0]))
             const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: ({ email:"", password:""})
            };
            return fetch('/users/authenticate', requestOptions)
            .then(handleResponse)
            .then(user => {
               setValue(man);
               setisuser("true");
               setTimeout(()=>{
                window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
                window.location.reload();
               }, 900)
              return user;
            });
                  })  

              });

    }
  }



  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    if(activeStep == 0){
      ValidateRoleandtp()
    }
    if(activeStep == 1){
      Validaterfc()
    }
    if(activeStep == 2){
      Validatedire()
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep == 3){
      Validatecyp()
    }
    if(activeStep == 4){
      Validatetyc()
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


    return (
      <Fragment>
            <div className="card-body bg-gradient-secondary text-center" >
            <img  style={{height:'45px',width:"200px", position:"relative", top:"-5.004px", left: "-419.508px"}} src={graph} alt=""/>
            <div class="page-content" style={{left:"0px", position:"relative", top: "-150px"}}>
		<div class="form-v8-content">
			<div class="form-left">
				<img src={banner} alt="form"/>
			</div>
			<div class="form-right">
				<div class="tab">
					<div class="tab-inner">
						<button class="tablinks" id="Iniciar" onClick={iniciar}>Iniciar</button>
					</div>
					<div class="tab-inner">
						<button class="tablinks" id="Inscribirse" onClick={inscribirse} >Inscribirse</button>
					</div>
				</div>
				<div class="tabcontent" id="sign-up">
                 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "25px", display: 'flex', alignItems: 'flex-end' }}>
                 <MailOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                 <TextField onChange={e => setEmail(e.target.value)} defaultValue={email} placeholder='E-mail' label="E-mail" className="form-control" color="error"  variant="standard"/>
                 </Box>
                 </label>
				 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "20px", display: 'flex', alignItems: 'flex-end' }}>
                 </Box>
                 </label>
				 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "25px", display: 'flex', alignItems: 'flex-end' }}>
                 <LockIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                 <TextField onChange={e => setPassword(e.target.value)} defaultValue={password} placeholder='Contraseña' label="Contraseña" type="password" className="form-control" color="error"  variant="standard"/>
                 </Box>
                 </label>
				 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "20px", display: 'flex', alignItems: 'flex-end' }}>
                 </Box>
                 </label>
                 <label class="form-row-inner">
                 <Box sx={{display: 'flex', alignItems: 'flex-end' }}>
                 <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    /><Label className="btn-link text-capitalize" style={{cursor:'pointer',color:"#003153",position: "relative",left: "-8.797px"}} for="tm" onClick={tyc}>{'Aceptar términos y condiciones'}</Label> 
                 </Box>
                 </label>
				 <label class="form-row-inner">
                 <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
				 <a onClick={refforget} target="_blank">
                        <Label style={{cursor:'pointer',color:"#cc0b0a"}} for="tm">{'¿Olvidaste tu contraseña?. Recupérala ahora'}</Label>  </a>
                 </Box>
                 </label>
				 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "10px", display: 'flex', alignItems: 'flex-end' }}>
                 </Box>
                 </label>
                 <Button style={{height: "50px", width: "150px"}} className="btn-pill" color="warning-gradien" onClick={loginAuth} >{"Inicia Sesión"}</Button>
                 <label class="form-row-inner">
                 <Box sx={{ width: "400px", height: "20px", display: 'flex', alignItems: 'flex-end' }}>
                 </Box>
                 </label>
         <Label style={{color:"#cc0b0a" , height: "10px"}} for="tm">{'PORTAL OPERANDO POR B2B NEGOCIOS, CONSULTA NUESTRO '}<a href="https://b2bnegocios.net/aviso-de-privacidad/" target="_blank"><Label className="btn-link text-capitalize" style={{cursor:'pointer',color:"#003153"}}for="tm">{'AVISO DE PRIVACIDAD'}</Label> </a></Label>
        </div>

                    <Modal isOpen={modal}  toggle={toggle}  centered={true} size="lg" style={{width:'530px'}} >
                    <ModalHeader  toggle={toggle}>
                      <h6>
                      {'TÉRMINOS Y CONDICIONES PARA EL USO DE LA APLICACIÓN DENOMINADA “B2B BUYSEL”, SITIO WEB WWW.APP.B2BUYSEL.COM PROPIEDAD DE PROCESOS ELECTRÓNICOS DE COMPRAS CORPORATIVAS, S.A.P.I. DE C.V. GENERALES'}
                      </h6>
                        </ModalHeader>
                        <ModalBody>
                        <ScrollArea horizontal={true} vertical={true}>   
                        <Col md="12">
Los presentes principios regirán para aquellas personas físicas con actividad empresarial y/o personas morales que pretendan hacer uso de la plataforma electrónica de servicios que provee PEEC en el sitio web.

Definiciones

USUARIO(S): Persona moral legalmente constituida y/o persona física con actividad empresarial que haga uso de la PLATAFORMA PEEC, ya sea para obtener los servicios que ahí se ofrecen, como para hacer uso de las herramientas, aplicaciones y/o software que la integran. Dicho Usuario, hará uso del sitio web www.app.b2buysel.com y todos sus subdominios, SIEMPRE a través de su apoderado legal o representante legal y/o persona autorizada para el caso de ser persona física con actividad empresarial, que cuente con las facultades suficientes y necesarias para: (a) Hacer uso del sitio web www. app.b2buysel.com y todos sus subdominios; (b) Crear una cuenta de usuario y contraseña en los términos previstos en dicho sitio; (c) Compartir la información propiedad y/o bajo responsabilidad de la persona física con actividad empresarial y/o de la persona moral que represente, información que le será solicitada para efectos de los servicios que requiera; (d) Autorizar a PECC para obtener información por parte de terceros que resulte necesaria.

PECC: Persona moral debidamente constituida conforme a las leyes de la república mexicana bajo la denominación social PROPIEDAD DE PROCESOS ELECTRÓNICOS DE COMPRAS CORPORATIVAS, S.A.P.I. DE C.V., con capacidad
legal suficiente y necesaria para obligarse al cumplimiento de los presentes términos y condiciones; con domicilio físico en Avenida Thiers, número 251, Colonia Anzures, Ciudad de México, Alcaldía Miguel Hidalgo, C.P. 11590, teléfono: 55 4440 4475, la cual presta servicios a través del sitio web www.app.b2buysel.com y todos sus subdominios, del cual es propietario.

Los términos y condiciones son un conjunto de términos legales definidos por el propietario de una página web y condiciones son un acuerdo entre el propietario del sitio web y los usuarios de la página web; detallan las políticas y procedimientos realizados por el sitio web, de conformidad a la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE PARTICULARES
LFPDPPP, esta ley se encarga de establecer lineamientos sobre la obtención, almacenamiento, manejo y uso de datos de los usuarios en un sitio web.

Antes de utilizar los servicios de la Aplicación denominada “B2B BUYSEL”, a
 
través del sitio web www.app.b2buysel.com, es preciso mencionar que es responsabilidad de los usuarios leer detenidamente los presentes términos y condiciones de uso. Al registrarse o utilizar la “Plataforma” como “Usuario” usted se sujeta y obliga a cumplir y respetar los presentes términos y condiciones, por lo que, si usted no está de acuerdo con estas condiciones, le recomendamos no usar esta Plataforma, los presentes términos y condiciones serán aplicables al acceso y uso que realicen los Usuarios dentro de la plataforma ya mencionada.

La navegación en la plataforma, así como las transacciones realizadas en la misma, son responsabilidad de los Usuarios.

La función de la aplicación tiene como finalidad la Solución para la Creación, Seguimiento y Adjudicación de Cotizaciones, así como el Acceso a una amplia base de Proveedores los cuales son Externos a la Organización del Comprador.

1.	ACEPTACIÓN

En el presente documento se establecen los términos y condiciones de uso de la aplicación, establecidos por PECC que serán de aplicación al acceso y uso por parte del Usuario de esta página Web, leer atentamente los presentes términos y condiciones.

Al acceder, consultar o utilizar el sitio web, los Usuarios aceptan cumplir los términos y condiciones establecidos en los presente términos y condiciones. En caso de que usted no acepte quedar vinculado por los presentes términos y condiciones, no podrá acceder a, ni utilizar, el sitio web.

PECC y sus respectivas empresas afiliadas se reservan el Derecho de actualizar los presentes términos y condiciones siempre que lo consideren oportuno. En consecuencia, recomendamos al Usuario revisar periódicamente las modificaciones efectuadas, las cuales se publicarán en la página cada que se efectúe dicho cambio.

PECC podrá incorporar cambios a los presentes Términos y Condiciones, según considere oportuno. En ese caso, PECC incluirá la nueva versión del mismo en el sitio de internet www.app.b2buysel.com y proporcionará los Términos y Condiciones adicionales en los servicios correspondientes o a través	de	estos.

El USUARIO reconoce y acepta qué si utiliza los Servicios después de haberse incorporado los cambios pertinentes a los presentes términos y condiciones, esto implicará una aceptación tácita.

El presente sitio web está dirigido exclusivamente a personas morales y físicas residentes en México, los usuarios residentes o domiciliados en otro país que
 
deseen acceder y utilizar el Sitio Web, lo harán bajo su propio riesgo y responsabilidad, por lo que deberán asegurarse de que dichos accesos y/o usos cumplen con la legislación aplicable en su país.

Para aceptar estos Términos y Condiciones, el USUARIO dispone de varias posibilidades:

(A)	Hacer clic para aceptar estos Términos y Condiciones, allí donde PECC ofrezca esta opción en la interfaz de usuario del Servicio en cuestión; o bien
(B)	Utilizar la PLATAFORMA PECC, en sí. En ese caso, PECC asumirá que el USUARIO acepta el presente documento, al empezar a usar la PLATAFORMA PECC; o bien
(C)	Emitir firma electrónica siguiendo el proceso que indique PECC, a efecto de firmar este documento, haciendo constar que en el caso de que el USUARIO acepte este procedimiento para dar su consentimiento, está consciente que la firma electrónica hará las veces de firma autógrafa, y al emitir y firmar el presente documento con la misma, estará aceptando en forma expresa todos y cada uno de los presentes términos y condiciones.

El USUARIO No podrá utilizar la PLATAFORMA PECC si no tiene la capacidad legal suficiente para poder obligarse al cumplimiento de los presentes términos y condiciones.

El usuario se obliga a leer detenidamente estos términos y condiciones, asegurándose de que comprende los mismos. En caso contrario o en caso de no aceptar alguna de nuestras cláusulas, queda bajo su responsabilidad utilizar dicho servicio.

Antes de continuar, le recomendamos imprimir este documento o guardar una copia del mismo en la unidad de disco local para su información.

2.	REQUISITOS RELATIVOS AL USUARIO
Edad Minina

El sitio web y los servicios relacionados con el mismo se ofrecen a los Usuarios que tengan capacidad legal para otorgar contratos legalmente vinculantes según la legislación aplicable.

Los menores no están autorizados para utilizar el Sitio Web. Si usted es menor de edad, por favor, no utilice esta web.

Empresas
Si usas el Servicio en nombre de una empresa, confirmas que tienes la capacidad LEGAL para actuar en nombre de dicha entidad y que aceptas lo establecido en este contrato.
 
3.	LICENCIA

En este acto, PECC otorga al Usuario una licencia limitada, no exclusiva, intransferible, no susceptible de cesión y revocable; para consultar y descargar, de forma temporal, una copia del contenido ofrecido en el sitio web, únicamente para uso personal del Usuario o dentro de su empresa, y nunca con fines comerciales.

Todo el material mostrado u ofrecido en el sitio web, entre otros ejemplos, el material gráfico, los documentos, textos, imágenes, sonido, video, audio, las ilustraciones, el software y el código HTML, en conjunto el contenido, es de exclusiva propiedad de PECC y/o de la empresa que ostenta la propiedad del mismo.

El contenido está protegido por las leyes de los Estados Unidos Mexicanos, e Internacionales, así como por las demás Leyes, Reglamentos y Normas aplicables a los derechos de Propiedad Intelectual. Salvo disposición expresa en contrario, y/o salvo que por imperativo legal ello esté expresamente permitido por leyes derogatorias de las actualmente vigentes, el Usuario no podrá utilizar, copiar, modificar, mostrar, eliminar, distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender, revender, adaptar, invertir el proceso de creación o crear productos derivados a partir del contenido. Tampoco podrá utilizar el contenido en otras páginas Web o en cualquier medio de comunicación como por ejemplo en un entorno de red, sin la previa autorización por escrito en este sentido de PECC.

Todas las marcas comerciales, las marcas de servicio y los logos mostrados en el Sitio Web son propiedad exclusiva de PECC y de sus respectivos propietarios.

El Usuario no podrá utilizar las marcas en modo alguno sin la previa autorización por escrito para ello de PECC y los respectivos propietarios.

4.	INFORMACIÓN FACILITADA POR EL USUARIO

El sitio web ofrece al Usuario y sirve para la Solución para la Creación, Seguimiento y Adjudicación de Cotizaciones, así como el Acceso a una amplia base de Proveedores los cuales son Externos a la Organización del Comprador, cuyas funciones generales son la Creación y Duplicación de Cotizaciones, Flujos de Autorización previos a la Publicación de la Cotización, Invitación a Proveedores Habituales, Invitación a nuevos Proveedores, Indicadores de interés, Mapa Comparativo de Respuestas, Adjudicación a uno o más Proveedores.

En esta etapa no existe conexión a ERP.
 
El Usuario otorga a PECC licencia y derecho permanente, no exclusivo, irrevocable, libre de royalties, durante el tiempo máximo permitido por la legislación aplicable, pero no le impone obligación de, utilizar, copiar, modificar, mostrar, distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender, comercializar, revender, adaptar ni crear productos derivados en todo o en parte a partir de la Información del Usuario, en ningún modo o manera.

El Usuario reconoce y acepta que la aplicación de PECC solamente tiene como fin almacenar y administrar información. PECC no comprueba ni controla la Información del Usuario. En consecuencia, PECC no asume garantía alguna en cuanto a la fiabilidad, precisión, integridad, validez o veracidad de la información remitida por los usuarios.

PECC se reserva el derecho de eliminar, retirar, negarse a reflejar o bloquear toda Información del Usuario que PECC considere como inaceptable. En caso de que PECC reciba alguna Notificación sobre la inaceptabilidad de determinada información facilitada por los Usuarios, PECC podrá con total discrecionalidad investigar dicha información.

El Usuario asume y acepta que PECC podrá conservar copia de la Información del Usuario y revelar dicha información a terceros si lo considera necesario para:

I.- Proteger la Integridad del sitio web; II.- Proteger los derechos de PECC;
III.- Cumplir una orden judicial;
IV.- Cumplir cualquier trámite legal;
V.- Hacer valer los derechos y acciones que asisten a PECC al tenor de los presentes términos; y
VI.- Satisfacer cualquier petición relativa a la infracción de derechos de terceros.

Cualquier derecho que no se otorgue expresamente en este contrato pertenece a B2B BUYSEL o en su caso a los titulares correspondientes.

5.	PROHIBICIÓN GENERAL

Al acceder a y utilizar el sitio web, el Usuario se compromete a NO:

A- Incumplir las Leyes, Reglamentos y Normas aplicables a nivel local, estatal y federal de conformidad a las legislaciones aplicables a los Estados Unidos Mexicanos, así como cualquier otra legislación aplicable incluyendo la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE
PARTICULARES LFPDPPP, cuando proceda;
 
B- Infringir los derechos de propiedad intelectual y de privacidad, entre otros, los derechos de patente (copyright), los derechos sobre la base de datos, las marcas registradas o el Know How de terceros;

C- Descargar, enviar, transmitir o almacenar material que:
a.- Sea ilegal, ofensivo, difamatorio, fraudulento, engañoso, que induzca a error, daño, amenaza, hostil, obsceno o censurable;
b.- Infrinja las obligaciones contractuales o de confidencialidad del usuario; c.- Perjudique o interfiera en las aplicaciones normales del sitio web, como el envío o la transmisión de virus, gusanos o troyanos, el envío continuado de material repetido o el envío de archivos inusualmente grandes; o
d.- Que no esté permitido por PECC como por ejemplo material publicitario no autorizado, material promocional no solicitado “correo basura”, “spams”, “cartas en cadena”, mensajes de venta directa piramidal, franquicias, material de distribución de asociación a un club, contratos de venta o cualquier otro material inaceptable;
e.- Vulnerar los derechos personales y de privacidad de terceros abusando del contenido, como por ejemplo acosando o molestando continuadamente a dichas personas enviándoles correos electrónicos no solicitados, o recabando información de carácter personal;
f.- Contravenir o intentar contravenir las medidas de seguridad del sitio web; g.- Utilizar cualquier aparato, procedimiento o mecanismo como, por ejemplo, spiders y robots de rastreo, para localizar, rescatar, buscar o acceder al sitio web o al contenido, sin el previo consentimiento por escrito de parte de PECC;
h.- Acceder o intentar acceder a la cuenta o al login de las terceras personas o empresas indicadas en el sitio web;
i.- Copiar, modificar, reproducir, eliminar, distribuir, descargar, almacenar, transmitir, vender, revender, publicar, invertir el proceso de creación o crear productos derivados a partir del contenido, excepto en lo que concierne al material remitido por el propio usuario y que es de su propiedad, o si así lo autorizan las leyes de propiedad intelectual aplicables;
j.- Enviar o facilitar información incorrecta, falsa o incompleta, en relación a los documentos enviados o en su perfil de usuario;
k.- Hacerse pasar por otra persona o empresa;
l.- Utilizar el sitio web de forma no autorizada o para alguna actividad delictiva;
m.- Falsificar la información de cabecera en el correo electrónico; o
n.- Falsear los datos sobre sí mismo, sobre su asociación con terceros o sobre su empresa.


6.	UTILIZACIÓN DEL SITIO Y OBLIGACIONES CONCRETAS

Además de las obligaciones generales, el Usuario deberá:
 
●	Utilizar el sitio web únicamente para los fines legalmente permitidos y para lo que fue creado.
●	Facilitar y mantener los datos personales que conforman la información del usuario de forma completa, correcta, actualizada y veraz;
●	Enviar	solamente	material	sobre	el	que	el	usuario	tenga	los correspondientes derechos o licencia para hacerlo;
●	Utilizar su propio criterio, precaución y sentido común al gestionar y utilizar el sitio web; y
●	Asumir el riesgo de confiar en el contenido, o utilizar la información, facilitados por terceros.

7.	CUENTA Y CONTRASEÑA

Cuando el Usuario utiliza el sitio web, puede optar por abrir una cuenta en el sitio. En ese caso, el Usuario recibirá el número de cuenta virtual y la contraseña inicial que le correspondan. Es responsabilidad exclusiva del Usuario:
1.- Mantener la confidencialidad de dichos números de cuenta virtual y contraseña;
2.- Actualizar y comprobar frecuentemente su contraseña; y
3.- Notificar inmediatamente a PECC si tiene conocimiento del uso no autorizado de su cuenta o de cualquier vulneración de las medidas de seguridad.

8.	VÍNCULOS A PÁGINAS WEB DE TERCEROS

El sitio web puede tener vínculos, como hipervínculos o enlaces que indican el acceso a páginas web de terceros, independientemente de que PECC revisa el material de terceros y solamente permite la inclusión de los sitios relacionados que, a su leal saber y entender no infringen la legislación aplicable, PECC no puede controlar ni realizar un seguimiento de los sitios relacionados y en consecuencia no asumirá responsabilidad alguna en cuanto a la exactitud, seguridad o fiabilidad del material, información o contenido incluido en dichos sitios relacionados.

La inclusión de los sitios relacionados en el sitio web no implica que haya relación o asociación alguna entre PECC y el propietario de los sitios relacionados, ni la aprobación o promoción por parte de PECC de dichos sitios relacionados. PECC incluye los sitios relacionados solamente para comodidad del Usuario. Este es el único responsable del acceso a los sitios relacionados.

El Usuario deberá utilizar su propio buen criterio, precaución y sentido común a la hora de usar los sitios relacionados y para ello recomendamos revisar el condicionado de uso y los términos que sobre privacidad rigen en dichos sitios relacionados. PECC se reserva el derecho de eliminar de su sitio web todos los sitios relacionados de los que tenga conocimiento real que están infringiendo
 
derechos de terceros y/o cuyo contenido vulnere la legislación aplicable, o si así se lo exige una orden judicial o una ordenanza administrativa.

Los vínculos a nuestra página web no están permitidos sin la expresa autorización previa y por escrito de PECC, el Usuario no podrá, ni solo ni en colaboración con otras personas ampliar, modificar, sustituir, enmendar o limitar, en todo o en parte la información ofrecida en nuestra página web ni las aplicaciones funcionales de la misma. Además, el Usuario no podrá ni solo ni en colaboración con otras personas crear o instalar vínculos desde su propia página web o desde webs de terceros, a la presente página web ya sea mediante vínculos de hipertexto, deep-linking, enmarcado, mediante códigos o de otra forma, ni en todo ni en parte, sin el previo consentimiento por escrito en este sentido de parte de PECC.

9.	PRIVACIDAD

PECC procesará toda la información personal que el Usuario introduzca o facilite a través de nuestro sitio web de conformidad con la política de privacidad del sitio web y los términos y condiciones relativos a la misma en los presente términos y condiciones de uso.

Para obtener información acerca de las prácticas de protección de datos personales y de información en general que aplica PECC, consulte nuestra política de privacidad para tratamiento de la información en general y aviso de privacidad para tratamiento de datos personales en este sitio. Estas políticas explican el tratamiento que hace PECC de su información y cómo protege su privacidad cuando utiliza los “Servicios”. El Aviso de Privacidad explica la forma en que serán tratados los datos personales que pudiera llegar a recabar directamente PECC por el uso que el USUARIO haga de los “Servicios”.

Por su parte, el USUARIO acepta el uso de su información de acuerdo con las políticas de privacidad de PECC.

El aviso de privacidad de PECC se encuentra en la página de internet www. app.b2buysel.com.

10.	COOKIES

En este sitio web utilizamos cookies. Las cookies son archivos de texto que contienen pequeñas cantidades de información, que se descargan en su computadora o dispositivo móvil cuando usted visita una página web. A cada nueva visita a la misma página, los datos almacenados son recuperados por el servidor de origen. Son útiles porque permiten que un sitio web reconozca el equipo del usuario.

Las cookies no pueden transmitir virus y tampoco dañan su computadora.
 
11.	PROPIEDAD DE LAS MARCAS

Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web, son propiedad exclusiva de PECC o de terceros, sin que pueda entenderse que el uso o acceso al sitio y/o a los servicios atribuya al usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos. El usuario no podrá hacer ningún uso de dichas marcas sin contar con el previo consentimiento de los respectivos titulares de las mismas.

Los contenidos difundidos a través del sitio web son propiedad intelectual de PECC o de terceros. Bajo ninguna circunstancia podrá interpretarse que los Usuarios adquieren derecho alguno sobre tales contenidos más allá de lo estrictamente necesario para el correcto uso del sitio y de los servicios en virtud del uso que hagan del sitio y/o de los contenidos provistos a través del mismo. Cualquier redistribución, retransmisión, publicación o utilización de cualquier material o contenido provisto a través del sitio se encuentra estrictamente prohibida sin el previo consentimiento escrito del respectivo titular o autor.

12.	LIMITACIÓN DE RESPONSABILIDAD

Bajo ninguna circunstancia PECC, sus empleados, sus proveedores, contratantes, directores o agentes serán responsables de cualquier daño, ya sea directo o indirecto, que resulte del uso o uso inapropiado del sitio web, incluyendo, pero no limitándose al uso del contenido y/o servicios ofrecidos a través del sitio web, así como los servicios en general ofrecidos por PECC.

13.	NOTIFICACIONES

PECC podrá notificar al Usuario sobre cualquier tema referido a la relación vigente entre el Usuario y PECC como titular del sitio web, por medio de correo electrónico, por comunicación escrita en soporte papel o por cualquier otro medio que a exclusivo criterio de PECC resulte efectivo y de acuerdo con la anticipación dispuesta para cada servicio de PECC en particular. En caso que PECC decida notificar vía comunicación escrita en soporte papel la comunicación correspondiente será enviada por correo al domicilio que el Usuario haya informado al momento de registrarse para darse de alta del servicio correspondiente. En todo momento, el Usuario podrá contactarse con PECC por medio de correo electrónico a 	 o a la dirección
	Por su parte, todas las notificaciones que efectúe PECC al Usuario se considerarán válidamente efectuadas si se han realizado a través de los medios anteriormente señalados.

14.	TERMINACIÓN
 
PECC se reserva el derecho de dar por terminado el acceso de los Usuarios a todo o parte del sitio y a todos o parte de los servicios ofrecidos por PECC debiendo únicamente notificar dicha circunstancia a él o los Usuarios de conformidad con lo establecido en los presentes Términos y Condiciones de Uso o de acuerdo con las condiciones particulares del servicio correspondiente. De la misma forma PECC se reserva el derecho de realizar devolución o reembolso alguno implícito en la cancelación de cualquiera de nuestros servicios, por concepto de servicios prestados o no prestados, en pagos anticipados; toda vez que usted acepte los presentes Términos y Condiciones al momento de contratar o renovar cualquiera de nuestros servicios.

PECCV podrá suspender o cancelar los servicios, sin responsabilidad alguna, en los siguientes supuestos:

(A)	El USUARIO ha incumplido alguna de las disposiciones aquí previstas, en cualquiera de sus Anexos o en las Políticas de Privacidad o ha actuado de algún modo que demuestre que no tiene intención de cumplir tales disposiciones o que no puede cumplirlas.
(B)	El USUARIO no provee de la información necesaria; haciendo imposible para PECC el proveerle de los “Servicios”.
(C)	Así se lo exige a PECC la ley, por ejemplo, en caso de que por nuevas disposiciones legales la provisión de los “Servicios” no sea permitida. En cualquiera de los supuestos anteriores, PECC ya no tendrá obligación alguna de proveerle los servicios aquí pactados, y el USUARIO quedará obligado a pagar las cantidades adeudadas a PECC por los servicios prestados y seguirá obligado, en su caso, directamente con los terceros con quienes haya contratado cualquier producto y/o servicio que haya requerido dentro de sus procesos el uso de la PLATAFORMA PECC.

15.	LEGISLACIÓN APLICABLE Y JURISDICCIÓN

Los presentes términos y condiciones de uso se rigen en todos y cada uno de sus extremos por las leyes de México. Por su parte PECC y el Usuario con renuncia expresa a cualquier otro fuero, por lo que se someten a la Jurisdicción de los Tribunales Competentes de la Ciudad de México, México.

                    </Col>
                    </ScrollArea> 
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle} >{"Cerrar"}</Button>
                        <Button color="primary" onClick={Acepto}>{"Acepto los términos y condiciones"}</Button> 
                        </ModalFooter>
                    </Modal>




					<div style={{position: "relative",top: "-43.0102px",height: "350px"}} class="tabcontent" id="sign-in">
					<label class="form-row-inner">
          <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep}  connector={<QontoConnector />} orientation="vertical">
        {steps.map((step, index) => (
          <Step style={{background:"cc0b0a"}} key={step.label}>
            <StepLabel
            StepIconComponent={QontoStepIcon}
              optional={
                index === 4 ? (
                  <Typography variant="caption">Último paso</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel> 
            <StepContent>
            <Typography>{step.h}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Buton
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    color="error"
                    type="submit" name="Submit"
                  >
                    {index === steps.length - 1 ? 'Registrarse' : 'Siguiente'}
                  </Buton>
                  <Buton
                    style={{display:"none"}}
                    disabled={true}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    color="error"
                  >
                    Atrás
                  </Buton>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
					                 </label>

					</div>
			</div>
		</div>
	</div>  
            </div>
			{/* <div className="card-body bg-gradient-primary text-center" >
	<img style={{width: "1500px",position: "relative", left: "-90px", top: "-318.994px"}} src={footer} /> 
	</div>  */}
			
      </Fragment>  
    )
}

export default Loginn
