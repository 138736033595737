import React,{useState,Fragment,useEffect} from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import { Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Media,Form,FormGroup,Label,Input,Button,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import {useSelector} from 'react-redux'
import SweetAlert from 'sweetalert2'
import prov from "../../assets/images/user/prov.png"
import comp from "../../assets/images/user/comp.png"
import axios from 'axios';
import { toast } from 'react-toastify';
import { Search} from 'react-feather';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CategoryUnspc from '../widgets/CategoryUnspc'


const UserEdit = (props) =>  {
 const currentUser= useSelector(state => state.Common.currentUser)
    const Obtener = () =>{
      window.location.href = `${process.env.PUBLIC_URL}/#/price/Pricing`
    }
    const Rpwd = () =>{
      window.location.href = `${process.env.PUBLIC_URL}/#/authentication/Resetpwd`
    }
    const [modal, setmodal] = useState(false);
    const [modal2, setmodal2] = useState(false);
    const [modal3, setmodal3] = useState(false);
    const [modal4, setmodal4] = useState(false);
    const [modal5, setmodal5] = useState(false);

//   useEffect(() => {
//     if(currentUser.role == "Proveedor"){
// document.getElementById('').style.display = 'none';
//     }if(currentUser.role == "Comprador"){
//       document.getElementById('gr').style.display = 'none';
//     }
// }, );      FUNCION DE BOTON



    const id = currentUser.id;
    const [nombreus, setnombreus] = useState ("");
    const [ape, setape] = useState ("");
    const [num, setnum] = useState ("");
    const [emp, setemp] = useState ("");
   
    const [catup, setcatup] = useState ([]);

    const toggle = () => {
      setmodal(false)
    }
    const toggle2 = () => {
      setmodal2(false)
    }
    const toggle3 = () => {
      setmodal3(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    
    }
    const toggle4 = () => {
      setmodal4(false)
    }
    const toggle5 = () => {
      setmodal5(false)
      setcatup([])
      
      localStorage.removeItem('selectedc')
    }
       




    const guardarcambios = () =>{

      if (estado3.Telefono == currentUser.Telefono && estado4.Empresa == currentUser.Empresa && estado2.Apellido == currentUser.Apellido && estado.Nombre == currentUser.Nombre){
        SweetAlert.fire({title:"ERROR", text:"No has escrito ningún cambio.", icon:"error"});        
      }

      else { 
        setmodal(true)
       }
                                 }


    const guardar = () => {

      const todo = {
        "id":currentUser.id,
       "Nombre":estado.Nombre,
       "Apellido":estado2.Apellido,
       "Empresa":estado4.Empresa,
       "Telefono":estado3.Telefono,
       "Categoria":currentUser.Categoria,
       "role":currentUser.role,
       "Correo":currentUser.Correo,
       "Contraseña":currentUser.Contraseña,
     }

     toast.error("Se recargara la pagina en breve");

     setTimeout(() => {
      axios.put("https://b2bhubapi.com:3001/pruebas/gc1", {
         Nombre:estado.Nombre,
         Apellido:estado2.Apellido,
         Telefono:estado3.Telefono,
         Empresa:estado4.Empresa,
         id:currentUser.id,
        }).then(res => {
          setmodal(false)
          SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente.", icon:"success"}); 
          sessionStorage.setItem('currentuser', JSON.stringify(todo));
        })
  }, 500);

       setTimeout(() => {
        
        window.location.reload();
      }, 3000);
    }

    const edcat =() => {
      setmodal2(true)
      setTimeout(() => {
        setmodal2(false)
        setmodal3(true)
      }, 1500);
    }

    const [options,setOptions] = useState([])
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [autocompleteValues, setAutocompleteValues] = useState([]);

  const handleChange = (event, value) => {

    setAutocompleteValues(value);
  };



 

    const buscar = () => {
      const cat = JSON.parse(localStorage.getItem('selectedc'))

      if(cat == "" | cat == null) {
        SweetAlert.fire({title:"No has seleccionado ningun dato", text:"Escribe en el campo para buscar una categoría", icon:"error"});
      }else {
        cat.map(data =>{
          const opcion = data.code + " - " + data.esp
          setcatup(opciones=>[...opciones, opcion])  
          
        })
    
        catup.forEach(function(elemento, indice, array) {   
          
        })
        
      setmodal2(true)
      setTimeout(() => {
               
        setmodal2(false)
        setmodal5(true)
      }, 1500);
    }
    }

    const seleccionar = () => {
      autocompleteValues.forEach(function(elemento, indice, array) {
        console.log(elemento.title, indice)
        
    })
    setcatup(autocompleteValues.join()) 
      setmodal5(true)
      
    }

    const Guardarcat = () => {
      const todo = {
        "id":currentUser.id,
       "Nombre":currentUser.Nombre,
       "Apellido":currentUser.Apellido,
       "Empresa":currentUser.Empresa,
       "Telefono":currentUser.Telefono,
       "Categoria":catup.join(),
       "role":currentUser.role,
       "Correo":currentUser.Correo,
       "Contraseña":currentUser.Contraseña,
     }
      setmodal3(false)
      setmodal5(false)
      setmodal4(false)
      toast.error("Se recargara la pagina en breve");

     setTimeout(() => {
      axios.put("https://b2bhubapi.com:3001/pruebas/gc2", {
         Categoria:catup.join(),
         id:currentUser.id,
        }).then(res => {
          setmodal(false)
          SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente.", icon:"success"}); 
          sessionStorage.setItem('currentuser', JSON.stringify(todo));
        })
  }, 500);

       setTimeout(() => {
        
        window.location.reload();
      }, 3000);

      
    }


 
    const [pimg, setpimg] = useState(null);
    const [urel, setUrel] = useState();
    const [imgp, setimgp] = useState("");
    const [perfilimg, setperfilimg] = useState("");

    
    

    useEffect(()=>{
    axios.get('https://24e4111190e1.ngrok.io/miimg',{
      params: {
        foo: currentUser.id
      }})
      .then((res) => {
        setperfilimg(res.data[0])
      })
    },[])
    const imgsrx = "https://24e4111190e1.ngrok.io/"+perfilimg;

    
    useEffect(()=>{
      axios.get('https://24e4111190e1.ngrok.io/request',{
      params: {
        foo: currentUser.id
      }}).then((response) => {
        setimgp(response.data.length);    
      });
    },[])

    const readUrl = (e) => {

      setpimg(e.target.files[0])


      if (e.target.files.length === 0)
          return;
      var mimeType = e.target.files[0].type;
     

      if (mimeType.match(/image\/*/) == null) {
          return;
      }    


      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_e) => {
          setUrel(reader.result)
          console.log(pimg)
      
          
      }   
  }


  const Mostrar = () => { 
  if (pimg === null) {
        return(
          <div>
            
          </div>
        )
  }if (imgp == 0) {
    return (    
      <Button className="btn btn-primary btn-block" color="secondary" onClick={botom}style={{position: "relative",top: "25.625px"}}>{"Guardar imagen"}</Button>
          )
  }
  if (imgp == 1) {
    return (    
      <Button className="btn btn-primary btn-block" color="secondary" onClick={botom2}style={{position: "relative",top: "25.625px"}}>{"Guardar imagen"}</Button>
          )
  }
}



  const botom = () =>{
    setmodal2(true)
    
    const formData = new FormData()
    formData.append('image', pimg, currentUser.id)
    axios.post('https://b23905fb1599.ngrok.io/images/post',
    formData
     
   ).then((res) => {
    setTimeout(() => {              
      setmodal2(false)    
      SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente.", icon:"success"}); 
    }, 1500);
    setTimeout(() => {
        
      window.location.reload();
    }, 3000);

   })
    .catch(err =>{
      console.error(err)
    })

  }
  
  const botom2 = () =>{
    setmodal2(true)
    
    const formData = new FormData()
    formData.append('image', pimg, currentUser.id)
    axios.put('https://b23905fb1599.ngrok.io/images/put',
    formData
     
   ).then((res) => {
    setTimeout(() => {              
      setmodal2(false)    
      SweetAlert.fire({title:"Completado", text:"Se han efectuado los cambios exitosamente.", icon:"success"});
      toast.error("Se recargara la pagina en breve");
    }, 1500);
    setTimeout(() => {
        
      window.location.reload();
    },4500);
   })
    .catch(err =>{
      console.error(err)
    })

  }




  const Imagendeperfil = () =>{

    if (imgp == 0 ) {

      if(currentUser.role =="Proveedor") {

        return(
                <div className="contact-profile">
              <img id="prove" className="rounded-circle img-100" src={urel ? urel : prov} alt="" />
              <div className="icon-wrapper">
               <i className="icofont icofont-pencil-alt-5">
              <input className="upload" type="file" onChange={readUrl} />
              </i>
               </div>
               </div>

        )
      }if (currentUser.role == "Comprador") {

        return (
              <div className="contact-profile">
              <img  id="compre" className="rounded-circle img-100" src={urel ? urel : comp} alt="" />
              <div className="icon-wrapper">
               <i className="icofont icofont-pencil-alt-5">
              <input className="upload" type="file" onChange={readUrl} />
              </i>
               </div>
               </div>

        )

      }
      if (currentUser.role == "Admin") {

        return (
              <div className="contact-profile">
              <img  id="compre" className="rounded-circle img-100" src={urel ? urel : comp} alt="" />
              <div className="icon-wrapper">
               <i className="icofont icofont-pencil-alt-5">
              <input className="upload" type="file" onChange={readUrl} />
              </i>
               </div>
               </div>

        )

      }


    }
    if (imgp == 1) {
      
      return (
        <div className="contact-profile">
              <img className="rounded-circle img-100" src={urel ? urel : imgsrx} alt="" />
              <div className="icon-wrapper">
               <i className="icofont icofont-pencil-alt-5">
              <input className="upload" type="file" onChange={readUrl} />
              </i>
               </div>
               </div>
      )

    }





  }



  useEffect(() => {
if(currentUser.role == "Comprador"){
      document.getElementById('catbot').style.display = 'none';
      document.getElementById('catin').style.display = 'none';
      document.getElementById('catlab').style.display = 'none';
    }
    if(currentUser.role == "Admin"){
      document.getElementById('catbot').style.display = 'none';
      document.getElementById('catin').style.display = 'none';
      document.getElementById('catlab').style.display = 'none';
    }

}, );


const [estado, setestado] = useState ({
  Nombre: currentUser.Nombre
});
const [estado2, setestado2] = useState ({
  Apellido: currentUser.Apellido
});
const [estado3, setestado3] = useState ({
  Telefono: currentUser.Telefono
});
const [estado4, setestado4] = useState ({
  Empresa: currentUser.Empresa
});


const elchange = event => {
setestado({ Nombre: event.target.value }, () =>
console.log(this.state.Nombre)
);
};
const elchange2 = event => {
  setestado2({ Apellido: event.target.value }, () =>
  console.log(this.state.Apellido)
  );
  };
  const elchange3 = event => {
    setestado3({ Telefono: event.target.value }, () =>
    console.log(this.state.Telefono)
    );
    };
    const elchange4 = event => {
      setestado4({ Empresa: event.target.value }, () =>
      console.log(this.state.Empresa)
      );
      };

 
    return (
      <Fragment>
       <Container fluid={true}>
       <Card>
         </Card>
       </Container>
        <Container fluid={true}>
          <div className="edit-profile">
            <Row>
              <Col lg="4">
              <Card>
                <CardBody style={{height: "300px"}}>
                  <Form>
                    <Row className="mb-2">
                    <Imagendeperfil/>
                        {/* <div  className="col-auto">
                          <Media id="com" className="img-70 rounded-circle" alt="" src={comp}/>   
                          <Media  id="pro" className="img-70 rounded-circle" alt="" src={prov}/>   
                        </div> */}
                      <Col>
                        <h6 className="mb-1">{currentUser.Nombre}</h6>
                        <h6 className="mb-3">{currentUser.Apellido}</h6>                       
                        <h9 className="mb-4">{currentUser.role}</h9>
                      </Col>
                      <p className="span badge badge-pill badge-primary" style={{ background:"#FFBF00",height: "20px",width: "128px",position: "relative",left: "2.32837px"}}>{"USUARIO GRATUITO"}</p>
                    </Row>
                    <div className="form-footer">
                      <Mostrar/>
                      {/* <Button className="btn btn-primary btn-block" color="primary" onClick={Obtener}style={{position: "relative",top: "25.625px"}}>{"Obtener Premium"}</Button> */}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
              <Col lg="8">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{"Editar Perfil"}</h4>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                <Row>
                    <Col lg="4">
                    <FormGroup>
                        <Label className="col-form-label">{"Nombre de usuario:"}</Label>
                        <Input className="form-control"  type="text" name="inputUsername" onChange={elchange} value={estado.Nombre} autoComplete="on" />
                      </FormGroup>
                    </Col>
                    <div className="col-sm-6 col-md-4">
                    <FormGroup>
                        <Label className="col-form-label">{"Apellido:"}</Label>
                        <Input className="form-control"  type="text" name="inputUsername" onChange={elchange2} value={estado2.Apellido} autoComplete="on" />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-md-4">
                    <FormGroup>
                        <Label className="col-form-label">{"Número de contacto:"}</Label>
                        <Input className="form-control"  type="text" name="inputUsername" onChange={elchange3} value={estado3.Telefono} autoComplete="on" />
                      </FormGroup>
                    </div>
                  </Row>

                  <Row>
                    <Col lg="4">
                  <FormGroup>
                        <Label className="col-form-label">{"Empresa:"}</Label>
                        <Input className="form-control"  type="text" name="inputUsername" onChange={elchange4} value={estado4.Empresa} autoComplete="on" />
                      </FormGroup> 
                      </Col>  
                      </Row>    
                      <Row>
                    <Col lg="4">
                      <FormGroup>
                      <Button color="primary" onClick={guardarcambios}>{"Guardar Cambios"}</Button>
                      <Modal isOpen={modal} toggle={toggle} size="lg" style={{width:'530px'}} centered>
                        <ModalHeader toggle={toggle}>{"¿Guardar los cambios?"}
                        </ModalHeader>
                        <ModalBody>
                        <h5 className="mb-4">{"Nombre de usuario: "}{estado.Nombre}{" "}{estado2.Apellido}</h5>
                        <h5 className="mb-4">{"Numero de telefono: "}{estado3.Telefono}</h5>  
                        <h5 className="mb-4">{"Empresa: "}{estado4.Empresa}</h5>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{"cancelar"}</Button>
                        <Button color="secondary" onClick={guardar}>{"Guardar"}</Button>
                        </ModalFooter>
                    </Modal>
                      </FormGroup>
                      </Col> 
                      </Row>
                      <CardHeader>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                    <Row>
                    <Col lg="4">
                  <FormGroup>
                        <Label id="catlab"className="col-form-label">{"Categoría de Productos (UNSPCS):"}</Label>
                        <Input id="catin" type="textarea" className="form-control" style={{width:800, height:80}} value={currentUser.Categoria}  />                        
                      </FormGroup>
                      <FormGroup>
                      <Button  id="catbot" color="primary"onClick={edcat}>{"Editar categoría"}</Button>
                      <Modal isOpen={modal2} toggle2={toggle2} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal3} toggle3={toggle3} size="lg" centered>
                        <ModalHeader toggle={toggle3}>{"Busca una nueva categoría de productos"}
                        </ModalHeader>
                        <ModalBody>
                        <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={toggle3}>{"Cancelar"}</Button>
                        <Button color="primary" onClick={buscar}>{"Siguiente"}</Button> 
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal4} toggle4={toggle4} size="lg" style={{width:'530px'}} centered>
                        <ModalHeader toggle4={toggle4}>{"Elige uno o varios productos"}
                        </ModalHeader>
                        <ModalBody>
                        <Autocomplete
                           multiple
                           id="checkboxes-tags-demo"                          
                           options={options}
                           disableCloseOnSelect
                           getOptionLabel={(option) => option}
                           renderOption={(props, option, { selected }) => (
                             <li {...props}>
                               
                               <Checkbox
                                 icon={icon}                                  
                                 checkedIcon={checkedIcon}
                                 style={{ marginRight: 7 }}
                                 checked={selected}
                               />
                               {option}
                             </li>
                           )}
                           onChange={handleChange}
                           style={{ width: 500 }}
                           renderInput={(params) => (
                             <TextField {...params} label="Seleccione una o varias opciones" />
                           )}
                             />
                                                   <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                            <h1>{'|'}</h1>
                            <h2>{'|'}</h2>
                                </font>
                                <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                            <h1>{'|'}</h1>
                            <h2>{'|'}</h2>
                                </font>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle4}>{"Cerrar"}</Button>
                        <Button color="secondary" onClick={seleccionar}>{"Seleccionar"}</Button>
                        </ModalFooter>
                        </Modal>
                        <Modal isOpen={modal5} toggle5={toggle5} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <h4 className="mb-4">{"Estas seguro de guardar los siguientes cambios: "}</h4>
                        <h6 className="mb-1">{catup.join()}</h6>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle5}>{"cancelar"}</Button>
                        <Button color="secondary" onClick={Guardarcat}>{"Guardar"}</Button>
                        </ModalFooter>
                    </Modal>
                      </FormGroup>
                      </Col>
                    </Row>
                    <CardHeader>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                    {/* <Row>
                    <Col lg="4">
                    <FormGroup>
                    <Label className="col-form-label">{"¿Olvidaste tu contraseña?..."}</Label>
                        <Button color="primary"onClick={Rpwd}>{"Reestablecer contraseña"}</Button>
                      </FormGroup>
                      </Col>
                    </Row> */}
                </CardBody>
              </Form>
            </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
}

export default UserEdit;